// import packages
import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import Slider from "react-slick";
import {Breadcrumb, Dropdown, Menu, Radio} from 'antd';
import {history} from "../../configs/history";
import {useParams} from 'react-router-dom';
import {Link} from "react-router-dom";

// Import assets
import "./house-details.scss"
import defaultFileLogo from '../../assets/images/default.png';
import {
    FilterIcon,
    CompassImage,
    UploadIcon,
    DramIcon,
    ArrowLeftIcon,
    ArrowRightIcon
} from "../../assets/images";

// Import Components
import {PrimaryButton} from "../../components/ui-elements/buttons/Buttons";
import ReserveModal from "../../components/modal/reserveModal/ReserveModal";
import {ImagesSlider} from "../../components/ui-elements/imagesZoomSlider/ImagesSlider";
import Calculator from "../calculator/Calculator";
import MetaTags from "../../components/MetaTags";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetAreaById, GetParkingById, ReserveHouse, GetBuildingByIdOrSlug, ViewHouses} from "../../redux/actions";
import {BUILDINGS_KEYS, HOUSE_BADGES, PROJECTS_CONSTS} from "../../constants/constants";
import {
    generateFileMediaUrl,
    generateFileMediaUrlWithoutDefault,
    generateImageMediaUrl
} from "../../utils/generateMediaUrl";
import {calculator, priceWithCommas} from "../../utils/helperFunctions";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import {
    MORTGAGE_PROGRAMS,
} from "../../constants/acceptedConsts";
import {AREA_TYPES} from "../../constants/constType";
import {resizeImage} from "../../utils/resizeImage";


function HouseDetails(props) {
    const {staticTexts, buildingById, badges} = props
    const [house, setHouse] = useState({})
    const [imagesSliderOpen, setImagesSliderOpen] = useState(false)
    const [calcWrapperOpen, setCalcWrapperOpen] = useState(false)
    const [renovatedValue, setRenovatedValue] = useState(false)
    const [modalCurrentImageIndex, setModalCurrentImageIndex] = useState(0)
    const [modalImagePathList, setModalImagePathList] = useState([])
    const [loading, setLoading] = useState("loading")
    const params = useParams()
    const [visible, setVisible] = useState(false);
    const [checkboxValue, setCheckboxValue] = useState("");
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [slideIndex, setSlideIndex] = useState(0);
    const [otherProjectsSlideIndex, setOtherProjectsSlideIndex] = useState(0);
    let sliderRef = useRef(null);
    let otherProjectsSliderRef = useRef(null);
    const calcRef = useRef(null);


    const renovatedPrice = (house?.project?.slug && PROJECTS_CONSTS[house?.project?.slug]?.renovatedPrice) || 0;
    const renovationStartingFloorNumber = house?.project?.slug && PROJECTS_CONSTS[house?.project?.slug]?.renovationStartingFloorNumber;
    const housePrice = (renovatedValue && house?.price) ? house?.price + (house?.area * renovatedPrice) : house?.price


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [house])

    useEffect(() => {
        window.scrollTo({
            top: calcRef?.current?.offsetTop,
            behavior: "smooth"
        });
    }, [calcWrapperOpen])

    useEffect(async () => {
        await params?.house && props.GetAreaById(params?.house).then((data) => {
            setHouse(data);
            props.ViewHouses(data.id)

            !data?.medias?.length ? setLoading("failed") : setLoading("loaded")
        }).catch(() => {
            history.push(getUrlWithLocal("/"))
        })

    }, [params.house,
        // props.selectedLanguage
    ])


    useEffect(async () => {
        // params.building && await props.GetBuildingByIdOrSlug(params.building)
    }, [])

    useEffect(() => {
        !calcWrapperOpen && calculate()
    }, [house, renovatedValue])

    useEffect(() => {
        (loading === "loaded" || loading === "failed")
        && (document.getElementById("compassImg")
            && (document.getElementById("compassImg").style.transform = `rotate(${house.compassAngle}deg)`))
    }, [loading])
    useEffect(() => {
        house?.medias?.length && setLoading("loaded")
    }, [house?.medias?.length])

    const nextSlide = (index, setState, ref) => {
        setState(index + 1)
        if (modalImagePathList?.length - 1 === index) {
            setState(index)
        }
        ref.slickNext();
    };

    const prevSlide = (index, setState, ref) => {
        setState(index === 0 ? 0 : index - 1)
        ref.slickPrev();
    };

    const toggleVisibility = () => {
        setVisible(!visible)
    }
    const getCalculatorMonthlyPrice = (price) => {
        setMonthlyPayment(price)
    }

    const openImagesSlider = (modalImagePathList, imageIndex) => {
        setImagesSliderOpen(true)
        setModalCurrentImageIndex(imageIndex);
        setModalImagePathList(modalImagePathList);
    }

    const closeImagesSlider = () => {
        setImagesSliderOpen(false)
        setTimeout(() => {
            setModalImagePathList([])
            setModalCurrentImageIndex(0)
        }, 150)
    }

    const toggleCalculatorWrapper = () => {
        setCalcWrapperOpen(true)
        if (calcWrapperOpen) {
            window.scrollTo({
                top: calcRef?.current?.offsetTop,
                behavior: "smooth"
            });
        }
    }
    const changeRenovatedValue = () => {
        setRenovatedValue(!renovatedValue)
    }
    const selectCheckbox = (e) => {
        setCheckboxValue(e.target.value)
    }
    const onClickFloor = (floor, num) => {
        history.push(getUrlWithLocal(`/building/${params.building}/floor/${floor}/house/${num}`))
    }

    function calculate() {
        let program;
        if (house.project && housePrice) {
            if (house.project.slug === PROJECTS_CONSTS.Garun.name) {
                program = MORTGAGE_PROGRAMS.INECOBANK.BOF
            } else {
                program = MORTGAGE_PROGRAMS.EVOCABANK.BOF
            }
            if (program?.maxPrice && housePrice > program.maxPrice) {
                return;
            }
            let percent = program?.percent || '';

            const advancePayment = housePrice * program?.advancePayment / 100

            let monthsCount = program?.maxMonths || 240
            let newHypothecAmount = housePrice - advancePayment
            let newRepaymentType = 0

            const totalData = calculator(newHypothecAmount, percent, monthsCount, newRepaymentType)
            setMonthlyPayment(totalData?.result?.totalPaymentInMonth)
        }
    }

    function downloadFile(id) {
        const pdf = house?.pdfs?.find(item => item?.id === id)
        if (pdf) {
            fetch(generateFileMediaUrl(pdf?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = pdf?.name + '.pdf';
                        a.click();
                    });
                });
        }
    }

    const settings = {
        dots: false,
        arrows: false,
        swipeToSlide: false,
        focusOnSelect: false,
        infinite: false,
        slidesToShow: 4,
    };

    const menuPdf = !!house?.pdfs?.length && (
        <Menu className="pdfs-dropdown">
            {house?.pdfs?.map((item) => {
                return <Menu.Item key={item.id}
                                  onClick={() => downloadFile(item?.id)}
                                  className={'pdf-dropdown-item'}>
                    <div>{item.name}</div>
                </Menu.Item>
            })
            }
        </Menu>
    );

    const btnTitle = !house?.availableInSecondaryMarket ? staticTexts?.house_right_info_reserve_now_btn : 'Available in secondary market'
    const project = props?.projects?.find(pr => pr.id === house?.project?.id)

    const breadcrumbFloorNumber = house?.floor?.isPenthouse ? house?.floor?.number + '_1' : house?.floor?.number;

    const metaTitle = `${house?.floor?.number === 1 || house?.floor?.number === -1 ?
        staticTexts?.rout_item_title_office : staticTexts?.rout_item_house} N ${house?.areaNumber}`

    return <div className={'HouseWrapper'}>
        <MetaTags title={metaTitle}
                  description={`${project?.title}\` ${project?.address}`}
                  image={resizeImage(generateImageMediaUrl(house?.medias?.[0]?.path), 1200, 660)}
        />
        <div className={'ContentWrapper'}>
            <div className={'HeaderWrapper'}>
                <div className={'header-left-wrapper'}>
                    {house?.areaNumber && <span
                        className={'header-title'}>
                         {AREA_TYPES.APARTMENT.key === house?.type && `${staticTexts?.rout_item_title_house} N ${house?.areaNumber}`}
                        {AREA_TYPES.OFFICE.key === house?.type && `${staticTexts?.rout_item_title_office} N ${house?.areaNumber}`}
                        {AREA_TYPES.PARKING.key === house?.type && `${staticTexts?.rout_item_title_parking} N ${house?.areaNumber}`}
                       </span>}
                    <Breadcrumb>
                        <Breadcrumb.Item><Link
                            to={getUrlWithLocal('/home')}>{staticTexts?.rout_item_home}</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link
                            to={getUrlWithLocal(`/buildings/${house?.project?.slug}`)}>{staticTexts?.rout_item_buildings}</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link
                            to={getUrlWithLocal(`/buildings/${house?.project?.slug}`)}>{`${staticTexts?.rout_item_title_building} ${BUILDINGS_KEYS[params?.building]}`}</Link></Breadcrumb.Item>
                        {breadcrumbFloorNumber && <Breadcrumb.Item>
                            <Link to={getUrlWithLocal(`/building/${params?.building}/floor/${breadcrumbFloorNumber}`)}>
                                {`${staticTexts?.rout_item_title_floor} ${house?.floor?.number}`}
                            </Link>
                        </Breadcrumb.Item>}
                        {house?.areaNumber && <Breadcrumb.Item>
                            {AREA_TYPES.APARTMENT.key === house?.type && `${staticTexts?.rout_item_house} N ${house?.areaNumber}`}
                            {AREA_TYPES.OFFICE.key === house?.type && `${staticTexts?.rout_item_office} N ${house?.areaNumber}`}
                            {AREA_TYPES.PARKING.key === house?.type && `${staticTexts?.rout_item_title_parking} N ${house?.areaNumber}`}
                        </Breadcrumb.Item>}
                    </Breadcrumb>
                </div>
                <div className={'header-right-wrapper'}>
                    <div className="building-wrapper">
                        {/*<Link to={getUrlWithLocal('/buildings')}><img src={BuildingsImage} alt={"building"}/></Link>*/}
                    </div>
                    {house?.floor?.number > PROJECTS_CONSTS?.[house?.project?.slug]?.office_floor && <Link to={{
                        pathname: getUrlWithLocal('/filter'),
                        state: {
                            building: params?.building,
                            floor: house?.floor?.number,
                            house: house?.rooms?.length < 4 ? house?.rooms?.length : 4
                        }
                    }} className={'item-card'}>
                        <div className={'icon-wrapper'}>
                            <FilterIcon/>
                        </div>
                        <div className={'item-card-content'}>
                            <div className={'item-card-content-title'}>{staticTexts?.rout_item_filter_title}</div>
                            <div className={'item-card-content-text'}>{staticTexts?.rout_item_filter_text}</div>
                        </div>
                    </Link>}
                </div>
            </div>
            <div className={"FloorWrapper"}>
                <div className={"FloorMainContent"}>
                    <div className={'LeftSide'}>
                        <div className={'left-side-slider'}>
                            {loading === "loaded" &&
                                <div className="slider-wrapper">
                                    <div className="main-img-wrapper">
                                        <div className="main-img-content">
                                            <img
                                                src={generateFileMediaUrlWithoutDefault(house?.medias?.[slideIndex]?.path)}
                                                className={'main-img'}
                                                // onLoad={() => setLoading("loaded")}
                                                onClick={() => openImagesSlider(house?.medias, slideIndex)} alt={""}
                                            />
                                        </div>
                                    </div>
                                    {/*{loading}*/}
                                    <div className="images-list-wrapper">
                                        {!!house?.medias?.length &&
                                            <Slider {...settings} ref={slider => (sliderRef = slider)}>
                                                {house?.medias?.map((item, index) => {
                                                    return <div key={item.id}
                                                                className={`list-item ${(slideIndex === index) ? 'active' : ''}`}>
                                                        <img
                                                            src={generateFileMediaUrl(item?.path)}
                                                            className={'list-img'}
                                                            onClick={() => setSlideIndex(index)} alt={"about"}/>
                                                    </div>
                                                })}
                                            </Slider>}
                                        {house?.medias?.length > 5 &&
                                            <>
                                                <button className={`slider-btn  prev-btn`}
                                                        onClick={() => prevSlide(slideIndex, setSlideIndex, sliderRef)}
                                                        disabled={slideIndex === 0}
                                                >
                                                    <ArrowLeftIcon/>
                                                </button>
                                                <button
                                                    className={`slider-btn next-btn`}
                                                    disabled={slideIndex === house?.medias?.length - 1}
                                                    onClick={() => nextSlide(slideIndex, setSlideIndex, sliderRef)}>
                                                    <ArrowRightIcon/>
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>}
                            {loading === "loading" && <div className={'image-loader'}/>}
                            {loading === "failed" && <div className="slider-wrapper">
                                <div className="main-img-wrapper">
                                    <div className="main-img-content">
                                        <img
                                            src={defaultFileLogo}
                                            className={'main-img'}
                                        />
                                    </div>
                                </div>
                            </div>}
                            {(loading === "loaded" || loading === "failed") && house?.floor?.number > 0 &&
                                <div className={'compass-wrapper compass-wrapper-in-left '}>
                                    <img id="compassImg" src={CompassImage} alt=""/>
                                </div>}
                        </div>
                        {house?.floor?.number > PROJECTS_CONSTS?.[house?.project?.slug]?.office_floor &&
                            <div className={'checkboxes-wrapper'}>
                                <Radio.Group value={checkboxValue} onChange={selectCheckbox}>
                                    {house?.projects?.length && <Radio className={'house-details-checkbox'}
                                                                       value={"other-projects"}>{staticTexts?.house_checkbox_other_projects}</Radio>}
                                    {house?.identicalAreas?.length && <Radio className={'house-details-checkbox'}
                                                                             value={"identical-houses"}>{staticTexts?.house_checkbox_identical_houses}</Radio>}
                                </Radio.Group>
                                {checkboxValue === "other-projects" &&
                                    <div className={'left-side-slider'}>
                                        <div className={'slider-wrapper other-projects-images-list'}>
                                            <div className="images-list-wrapper">
                                                {!!house?.projects?.length &&
                                                    <Slider {...settings}
                                                            ref={slider => (otherProjectsSliderRef = slider)}
                                                            slidesToShow={3}>
                                                        {house?.projects?.map((item, index) => {
                                                            return <div key={item.id}
                                                                        className={`list-item`}>
                                                                <img
                                                                    src={generateFileMediaUrl(item?.path)}
                                                                    className={'list-img'}
                                                                    alt={"about"} onClick={() => {
                                                                    openImagesSlider(house?.projects, index)
                                                                }}/>
                                                            </div>
                                                        })}
                                                    </Slider>}
                                                {house?.projects?.length > 3 &&
                                                    <>
                                                        <button className={`slider-btn prev-btn`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    prevSlide(otherProjectsSlideIndex, setOtherProjectsSlideIndex, otherProjectsSliderRef)
                                                                }}
                                                                disabled={otherProjectsSlideIndex === 0}
                                                        >
                                                            <ArrowLeftIcon/>
                                                        </button>
                                                        <button
                                                            className={`slider-btn next-btn`}
                                                            disabled={otherProjectsSlideIndex === house?.projects?.length - 1}
                                                            onClick={() => {
                                                                nextSlide(otherProjectsSlideIndex, setOtherProjectsSlideIndex, otherProjectsSliderRef)
                                                            }}>
                                                            <ArrowRightIcon/>
                                                        </button>
                                                    </>}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {checkboxValue === "identical-houses" &&
                                    <div>
                                        <div className="identical-houses-list">
                                            {buildingById && !!buildingById?.floors?.length && buildingById?.floors?.slice(2).map(item => {
                                                let identicalHouse = house?.identicalAreas.find(h => h?.floor?.id === item?.id)
                                                let badge = identicalHouse?.id && badges?.find(badge => badge?.id === identicalHouse?.badge)
                                                return <div
                                                    onClick={() => identicalHouse?.id && onClickFloor(item?.number, identicalHouse?.number)}
                                                    style={badge && {
                                                        background: badge?.color,
                                                        color: "white",
                                                        cursor: "pointer"
                                                    }}
                                                    key={item?.id}
                                                    className={`list-item ${item.number === house?.floor?.number ? 'active' : (item.inactive ? 'inactive-floor' : '')}`}>{item?.number}</div>
                                            })}
                                        </div>
                                        <div className={'badges-wrapper'}>
                                            {badges && !!badges?.length && badges.map(item => {
                                                return <div className={'badge-item'} key={item?.id}>
                                                    <div className={'badge-color'} style={{background: item.color}}/>
                                                    <div>{item?.title}</div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>}
                    </div>
                    <div className={'RightSide'}>
                        {/*         {BUILDINGS_360_LINKS?.[house?.project?.slug] && <Link
                            // to={getUrlWithLocal(`stories-360/${house?.project?.slug}/${PROJECTS_CONSTS[house?.project?.slug]?.floor_count}`)}
                            to={getUrlWithLocal(`stories-360/${house?.project?.slug}/${params?.num}`)}
                            className="view-360">
                            <Icon360/>
                        </Link>}*/}

                        {(house?.floor?.number > PROJECTS_CONSTS?.[house?.project?.slug]?.office_floor) &&
                            <div className={'upload-pdf-wrapper'}>
                                <div className={'house-badge'} style={{color: house?.badge?.color}}>
                                    {house?.badge?.title}
                                </div>
                                {house?.pdfs?.length ? <Dropdown overlay={menuPdf}
                                                                 trigger={['click']}
                                                                 overlayClassName={'pdfs-wrapper'}
                                                                 placement={"bottomRight"}
                                                                 size={'small'}>
                                        <div className={`upload-btn ${house?.pdfs?.length ? "active" : ""}`}>
                                            {staticTexts?.house_right_info_upload_btn}<UploadIcon/>
                                        </div>
                                    </Dropdown>
                                    : <div className={`upload-btn`}>
                                        {staticTexts?.house_right_info_upload_btn}<UploadIcon/>
                                    </div>
                                }
                            </div>}
                        {house?.areaNumber && <div
                            className={'header-title'}>
                            {AREA_TYPES.APARTMENT.key === house?.type && `${staticTexts?.house_right_info_title} N ${house?.areaNumber}`}
                            {AREA_TYPES.OFFICE.key === house?.type && `${staticTexts?.rout_item_title_office} N ${house?.areaNumber}`}
                            {AREA_TYPES.PARKING.key === house?.type && `${staticTexts?.rout_item_title_parking} N ${house?.areaNumber}`}
                        </div>}
                        <div className={'areas-list'}>
                            {!!house?.area && <div className={'list-item'}>
                                <div className={'item-name'}>{staticTexts?.house_right_info_area}</div>
                                <div className={'line'}/>
                                <div
                                    className={'item-area'}>{`${house?.area} ${staticTexts?.measurement_unit_meter}`}<sup>2</sup>
                                </div>
                            </div>}
                            {!!house?.cadastralArea && <div className={'list-item'}>
                                <div className={'item-name'}>{staticTexts?.house_right_info_cadastral_area}</div>
                                <div className={'line'}/>
                                <div
                                    className={'item-area'}>{`${house?.cadastralArea} ${staticTexts?.measurement_unit_meter}`}<sup>2</sup>
                                </div>
                            </div>}
                            {house?.rooms && !!house?.rooms?.length && house?.rooms.map(item => {
                                return <div className={'list-item'} key={item?.id}>
                                    <div className={'item-name'}>{item?.title}</div>
                                    <div className={'line'}/>
                                    <div
                                        className={'item-area'}>{`${item?.area} ${staticTexts?.measurement_unit_meter}`}<sup>2</sup>
                                    </div>
                                </div>
                            })}
                            {
                                !!renovatedPrice &&
                                house?.floor?.number >= renovationStartingFloorNumber &&
                                !house?.availableInSecondaryMarket &&
                                <Radio checked={renovatedValue}
                                       onClick={changeRenovatedValue}
                                       className={"house-details-checkbox"}>
                                    {staticTexts?.house_right_info_renovated_checkbox}
                                </Radio>
                            }
                        </div>
                        {(house?.id && house?.badge?.id !== HOUSE_BADGES?.sold?.key) &&
                            <div className={'price-wrapper'}>
                                {!house?.availableInSecondaryMarket &&
                                    (housePrice ? <div className={'price'}>{priceWithCommas(housePrice)}
                                            <DramIcon/></div>
                                        : <div
                                            className={'price'}>{staticTexts?.house_right_info_price_contractual}</div>)
                                }
                                <PrimaryButton disabled={house?.badge?.id !== HOUSE_BADGES?.available?.key}
                                               title={btnTitle}
                                               cb={toggleVisibility}/>
                                <ReserveModal area={house} visible={visible} toggleVisibility={toggleVisibility}/>
                            </div>}
                        {!house?.availableInSecondaryMarket && house?.floor?.number > PROJECTS_CONSTS?.[house?.project?.slug]?.office_floor &&
                            <div className="linkWrapper">
                                <div>
                                    <span>{staticTexts?.house_right_info_link_text}</span>
                                    <span className={"link-text"}
                                          onClick={toggleCalculatorWrapper}
                                          ref={calcRef}>{staticTexts?.house_right_info_link_blue_text}</span>
                                </div>
                                {housePrice && <div className={"monthly-price-wrapper"}>
                                    <span>{staticTexts?.house_right_info_monthly_payment}</span>
                                    <span
                                        className={"monthly-price"}>{priceWithCommas(monthlyPayment)}
                                        <DramIcon/></span>
                                </div>}
                            </div>}
                    </div>
                </div>
                {calcWrapperOpen && <Calculator project={house?.project}
                                                fromHouseDetails
                                                hypothecAmount={housePrice}
                                                calculate={calculate}
                                                getCalculatorMonthlyPrice={getCalculatorMonthlyPrice}
                />}
            </div>
        </div>
        <ImagesSlider visible={imagesSliderOpen}
                      title={`${house?.floor?.number > PROJECTS_CONSTS?.[house?.project?.slug]?.office_floor ? staticTexts?.rout_item_house :
                          (house?.floor?.number > 0 && house?.floor?.number <= PROJECTS_CONSTS?.[house?.project?.slug]?.office_floor ? staticTexts?.rout_item_office : staticTexts?.rout_item_title_parking)} N ${house?.areaNumber || house?.areaNumber}`}
                      modalImagePathList={modalImagePathList}
                      modalCurrentImageIndex={modalCurrentImageIndex}
                      closeModal={closeImagesSlider}/>
    </div>

}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'selectedLanguage',
    'banksList',
    'buildingById',
    'badges',
    'projects',
]);

const mapDispatchToProps = {
    GetAreaById,
    GetParkingById,
    ReserveHouse,
    GetBuildingByIdOrSlug,
    ViewHouses
};

export default connect(mapStateToProps, mapDispatchToProps)(HouseDetails);
