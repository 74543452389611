
export const StaticTexts = {
    en: {
        //Header
        header_construction: "Construction management",

        //Menu
        menu_about: "About",
        menu_contact: "Contact",
        menu_apartments: "Apartments",
        menu_calculator: "Calculator",

        //Homepage Slider
        homepage_slider_project_view: "View complex",
        homepage_slider_apartment_placeholder: "Apartment type",
        homepage_slider_rooms_count_placeholder: "Rooms count",
        homepage_slider_project_placeholder: "Select project",
        homepage_slider_from: "from",
        homepage_slider_to: "to",
        homepage_slider_button_houses: "Բնակարաններ-en",
        homepage_mobile_address_button: "About project",

        //Homepage About
        homepage_about_box_title: "About company",
        homepage_about_box_description: "We are a Armenian company specialized in construction industry.",
        homepage_about_features_title: "You need get our services to evaluate your company work and time.",
        homepage_about_feature_1: "Modern architectural style.",
        homepage_about_feature_2: "You have a business with 12% investment",
        homepage_about_feature_3: "Served parkings and game zone",
        homepage_about_question: "If you have any question?",
        homepage_about_question_button: "Let’s talk!",
        homepage_about_projects_count: "successful projects",
        homepage_about_years_count: "years of experience",
        homepage_about_prof_count: "profesional speciali",
        homepage_about_sub_title: "We working in",

        //Homepage projects
        homepage_projects_question: "Choose apartament ?",
        homepage_projects_question_link: "Here",
        homepage_projects_about_btn: "About complex",

        //Homepage Futures
        homepage_futures_title_line_1: "Why you need",
        homepage_futures_title_line_2: "turn to us ?",
        homepage_futures_question: "Any question ?",
        homepage_futures_calculate: "Calculate",
        homepage_futures_here: "here",
        homepage_futures_item_title_1: "Cost reduction",
        homepage_futures_item_description_1: "Smart service access helps your apartament expenses",
        homepage_futures_item_title_2: "New approach",
        homepage_futures_item_description_2: "Personal manager and a dedicated support team",
        homepage_futures_item_title_3: "Save time",
        homepage_futures_item_description_3: "With full information on price, addresses and order times",
        homepage_futures_item_title_4: "Modern design",
        homepage_futures_item_description_4: "Personal manager and a dedicated support team",

        //Homepage Contact
        homepage_contact_title: "Եղիր ավելի տեղեկացված-en",
        homepage_contact_text: "Մենք համոզված ենք, որ շինարարական բիզնեսում լավագույն հատկանիշը  երեք տալ այդգնի և որակի լավագույն տարբերակ-en",
        homepage_contact_placeholder_email: " Ել․ հասցե-en",
        homepage_contact_button_send: "Ուղարկել-en",

        //Footer
        footer_section_security_title: "Անվտանգություն-en",
        footer_section_privacy: "Գաղտնիություն-en",
        footer_section_terms_and_conditions: "Ընդհանուր դրույթներ և պայմաններ-en",
        footer_section_company_title: "Ընկերություն-en",
        footer_section_about: "Մեր մասին-en",
        footer_section_filters: "Ֆիլտրեր-en",
        footer_section_calculator: "Հաշվիչ-en",
        footer_section_contact_title: "Հաշվիչ-en",
        footer_section_copyright_text: "© Copyright 2020 Online Supermarket All rights reserved.-en",
        footer_section_developed_by_text: "Մշակումը ՝",
        footer_section_buildings_title: "Նախագծեր",

        //About
        about_page_count_projects: "Նախագծեր-en",
        about_page_count_commercials: "Կոմերցիոն-en",
        about_page_count_houses: "Բնակարաններ-en",
        about_page_count_workers: "Աշխատակիցներ-en",
        about_page_btn_contact: "Հետադարձ կապ-en",
        about_page_btn_houses: "Բնակարաններ-en",
        about_building_number:'22',
        about_building_partners:'22',
        about_building_customers:'22',

        //Contacts
        contacts_page_title: "Հետադարձ կապ-en",
        contacts_page_left_subTitle: "Կոնտակտային տվյալներ-en",
        contacts_page_left_text: "Մենք համոզված ենք, որ շինարարական բիզնեսում:-en",
        contacts_page_info_phone_title: "Հեռ․-en",
        contacts_page_info_email_title: "Էլ․ հասցե-en",
        contacts_page_info_address_title: "Հասցե-en",
        contacts_page_right_subTitle: "Ուղարկել հաղորդագրություն-en",
        contacts_page_right_text: "Մենք համոզված ենք, որ շինարարական բիզնեսում:-en",
        contacts_page_placeholder_name: "Անուն, ազգանուն-en",
        contacts_page_placeholder_email: "Էլ․ հասցե-en",
        contacts_page_placeholder_phoneNumber: "Հեռ. համար-en",
        contacts_page_placeholder_message: "Հաղորդագրոթյուն-en",
        contacts_page_btn_send_message: "Ուղարկել-en",

        //Routs
        rout_item_home: "Գլխավոր-en",
        rout_item_buildings: "Մասնաշենքեր-en",
        rout_item_title_building: "Մասնաշենք-en",
        rout_item_building: "Մասնաշենք-en",
        rout_item_title_floor: "Հարկ-en",
        rout_item_floor: "Հարկ-en",
        rout_item_house: "Բնակարան-en",
        rout_item_office: "Բնակարան-en",
        rout_item_title_house: "Բնակարան-en",
        rout_item_title_office: "Office",
        rout_item_title_parking: "Բնակարան-en",
        rout_item_address_title: "Հասցեն-en",
        rout_item_filter_title: "Ֆիլտր-en",
        rout_item_filter_text: "Խմբավորել-en",
        rout_item_filter_title_choose_house : "Բնակարանների ընտրություն-en",
        rout_item_calculator_title: "Հաշվիչ-en",
        rout_item_calculator_text: "Հաշվիչ-en",

        // //Badge
        // badge_is_available: "Առկա է-en",
        // badge_is_reserved: "Ամրագրված է-en",
        // badge_is_sold: "Վաճառված է-en",

        //Mouse Tooltip
        mouse_info_building: "Շենք-en",
        mouse_info_floor: "հարկ-en",
        mouse_info_house: "բնակարան-en",
        mouse_info_office: "բնակարան-en",
        mouse_info_room: "սենյակ-en",
        mouse_info_is_available: "առկա է-en",

        //House Details
        house_right_info_title: "Բնակարան-en",
        house_right_info_upload_btn: "Ներբեռնել PDF-en",
        house_checkbox_other_projects: "Այլընտրանքային նախագծեր",
        house_checkbox_identical_houses: "Նմանատիպ բնակարաններ այլ հարկերում",
        house_right_info_cadastral_area: "~ cadastral area",
        house_right_info_area: "Ընդհանուր մակերես-en",
        house_right_info_renovated_checkbox: "Վերանորոգված-en",
        house_right_info_price_contractual: "Պայմանագրային-en",
        house_right_info_reserve_now_btn: "Ամրագրել հիմա-en",
        house_right_info_secondary_market_btn: "Available in secondary market",
        house_right_info_link_text: "Ծանոթացեք հիպոթեքի պայմաններին -en",
        house_right_info_link_blue_text: " այստեղ-en",
        house_right_info_monthly_payment: " միջին ամսական վճար",

        //Units of measurement
        measurement_unit_meter: "մ-en",
        measurement_unit_dram: "Դ-en",

        //Modal
        modal_title_house: "Ամրագրել բնակարան-en",
        modal_title_parking: "Ամրագրել բնակարան-en",
        modal_title_office: "Ամրագրել բնակարան-en",
        modal_success_message: "Հաջողությամբ կատարվեց-en",
        modal_error_message: "Հաջողությամբ կատարվեց-en",
        modal_name_label: "Անուն, ազգանուն-en",
        modal_email_label: "Էլ․ հասցե-en",
        modal_phone_label: "ՀԵռ․ համար-en",
        modal_house_number_label: "Բնակարանի համար-en",
        modal_btn_cancel: "Չեղարկել-en",
        modal_btn_close: "Փակել-en",
        modal_btn_save: "Պահպանել-en",
        call_modal_title: "заявка на звонок-en",
        call_time_placeholder: "Other time-en",
        call_time_picker_placeholder:"Time-en",


        //Filter
        filter_building_title: "Մասնաշենք-en",
        filter_projects_title: "Choose complex-en",
        filter_floors_title: "Հարկեր-en",
        filter_sort_placeholder: "Դասակարգել ըստ-en",
        filter_floors_dropdown_btn_cancel: "Չեղարկել-en",
        filter_floors_dropdown_btn_remember: "Հիշել-en",
        filter_rooms_count_title: "Ննջասենյակ-en",
        filter_area_title: "Մակերես-en",
        filter_min_area_placeholder: "սկսած-en",
        filter_max_area_placeholder: "մինչև-en",
        filter_price_title: "Գին-en",
        filter_min_price_placeholder: "սկսած-en",
        filter_max_price_placeholder: "մինչև-en",
        filter_clear_filters: "Չեղարկել-en",
        filter_showing_count: "Found %d variants in %l buildings-en",
        filter_open_filter_menu: "Construction button-en",
        filter_360_btn: "View 360°-en",
        filter_3d_btn: "Choose from 3D-version-en",
        filter_show_more_btn: "Show more variants-en",
        filter_project_areas_count: "Showing %d variant from %l-en",
        filter_sort_type_rooms_count: "count",
        filter_sort_type_area: "area",
        filter_sort_type_price: "price",

        //Types
        sort_by_price_increase: "Գնի աճման-en",
        sort_by_price_reduction: "Գնի նավազման-en",
        sort_by_area_increase: "Մակերեսի աճման-en",
        sort_by_area_reduction: "Մակերեսի նավազման-en",
        sort_by_rooms_count_increase: "Սենյակների աճման-en",
        sort_by_rooms_count_reduction: "Սենյակների նավազման-en",
        area_type_apartment: 'Բնակարան-en',
        area_type_office: 'Գրասենյակային տարածք-en',
        area_type_hotel: 'Հյուրանոց-en',
        area_type_perking: 'Ավտոկայանատեղի-en',

        //House Card
        house_card_price_contractual: "Պայմանագրային-en",
        house_card_area_title: "Մակերես-en",
        house_card_floor_title: "Հարկ-en",
        house_card_rooms_title: "Սենյակներ-en",
        house_card_reverse_btn: "Ամրագրել-en",
        house_card_reserve: "Reserve",

        //Calculator
        calculator_left_title: "Հիփոթեքի հաշվիչ-en",
        calculator_hypothec_price_label: "Հիփոթեքի գումարը (ՀՀԴ)-en",
        calculator_hypothec_price_placeholder: "Հիփոթեքի գումար-en",
        calculator_hypothec_advancePayment_label: "Կանխավճար-en",
        calculator_hypothec_advancePayment_placeholder: "Կանխավճար-en",
        calculator_hypothec_date_label: "Ժամկետ-en",
        calculator_hypothec_date_placeholder: "Ժամկետ-en",
        calculator_hypothec_type_label: "Տեսակ-en",
        calculator_hypothec_type_placeholder: "Տեսակ-en",
        calculator_hypothec_type_mount: "Ամիս-en",
        calculator_hypothec_type_year: "Տարի-en",
        calculator_repayment_type_annuity: "Անուիտետ-en",
        calculator_repayment_type_differentiated: "Դիֆերենցված-en",
        calculator_hypothec_percent_label: "Տարեկան տոկոսադրույք (%)-en",
        calculator_hypothec_percent_placeholder: "Տարեկան տոկոսադրույք-en",
        calculator_bank_title_label: "Բանկ-en",
        calculator_bank_title_placeholder: "Բանկ-en",
        calculator_cancel_btn: "Չեղարկել-en",
        calculator_calculate_btn: "Հաշվել-en",
        calculator_right_title: "Վճար (ՀՀԴ)-en",
        calculator_right_box_hypothec_price_title: "Հիփոթեքի գումարը (ՀՀԴ)-en",
        calculator_right_box_hypothec_date_title: "Ժամկետ-en",
        calculator_right_box_type_mount: "ամիս-en",
        calculator_right_box_type_year: "տարի-en",
        calculator_right_box_hypothec_percent_title: "Տարեկան տոկոսադրույք (%)-en",
        calculator_right_box_main_price_title: "Մայր գումար-en",
        calculator_right_box_advance_payment_title: "Կանխավճար-en",
        calculator_right_box_percent_title: "Տոկոս-en",
        calculator_right_box_income_tax_title: "Եկամտահարկ-en",
        calculator_right_box_monthly_payment_title: "Ամսական վճար (ՀՀԴ)-en",
        calculator_table_column_month: "Ամիս-en",
        calculator_table_column_percent: "Վճարվող տոկոսագումար-en",
        calculator_table_main_amount: "Մայր գումար-en",
        calculator_table_monthly_payment: "Ընդամենը վճարում-en",

        //Coming Soon
        coming_soon_1 : "Coming",
        coming_soon_2 : "soon",

    },
    hy: {
        //Header
        header_construction: "Construction management",

        //Menu
        menu_about: "About",
        menu_contact: "Contact",
        menu_apartments: "Apartments",
        menu_calculator: "Calculator",

        //Homepage Slider
        homepage_slider_project_view: "View complex",
        homepage_slider_apartment_placeholder: "Apartment type",
        homepage_slider_rooms_count_placeholder: "Rooms count",
        homepage_slider_project_placeholder: "Select project",
        homepage_slider_from: "from",
        homepage_slider_to: "to",
        homepage_slider_button_houses: "See %d variants",
        homepage_mobile_address_button: "About project-hy",

        //Homepage About
        homepage_about_box_title: "About company",
        homepage_about_box_description: "We are a Armenian company specialized in construction industry.",
        homepage_about_features_title: "You need get our services to evaluate your company work and time.",
        homepage_about_feature_1: "Modern architectural style.",
        homepage_about_feature_2: "You have a business with 12% investment",
        homepage_about_feature_3: "Served parkings and game zone",
        homepage_about_question: "If you have any question?",
        homepage_about_question_button: "Let’s talk!",
        homepage_about_projects_count: "successful projects",
        homepage_about_years_count: "years of experience",
        homepage_about_prof_count: "profesional speciali",
        homepage_about_sub_title: "We working in",

        //Homepage projects
        homepage_projects_question: "Choose apartament ?",
        homepage_projects_question_link: "Here",
        homepage_projects_about_btn: "About complex",

        //Homepage Futures
        homepage_futures_title_line_1: "Why you need",
        homepage_futures_title_line_2: "turn to us ?",
        homepage_futures_question: "Any question ?",
        homepage_futures_calculate: "Calculate",
        homepage_futures_here: "here",
        homepage_futures_item_title_1: "Cost reduction",
        homepage_futures_item_description_1: "Smart service access helps your apartament expenses",
        homepage_futures_item_title_2: "New approach",
        homepage_futures_item_description_2: "Personal manager and a dedicated support team",
        homepage_futures_item_title_3: "Save time",
        homepage_futures_item_description_3: "With full information on price, addresses and order times",
        homepage_futures_item_title_4: "Modern design",
        homepage_futures_item_description_4: "Personal manager and a dedicated support team",

        //Homepage Contact
        homepage_contact_title: "Եղիր ավելի տեղեկացված",
        homepage_contact_text: "Մենք համոզված ենք, որ շինարարական բիզնեսում լավագույն հատկանիշը  երեք տալ այդգնի և որակի լավագույն տարբերակ",
        homepage_contact_placeholder_email: " Ել․ հասցե",
        homepage_contact_button_send: "Ուղարկել",

        //Footer
        footer_section_security_title: "Անվտանգություն",
        footer_section_privacy: "Գաղտնիություն",
        footer_section_terms_and_conditions: "Ընդհանուր դրույթներ և պայմաններ",
        footer_section_company_title: "Ընկերություն",
        footer_section_about: "Մեր մասին",
        footer_section_filters: "Ֆիլտրեր",
        footer_section_calculator: "Հաշվիչ",
        footer_section_contact_title: "Հաշվիչ",
        footer_section_copyright_text: "© Copyright 2020 Online Supermarket All rights reserved.",
        footer_section_developed_by_text: "Մշակումը ՝",
        footer_section_buildings_title: "Նախագծեր",

        //About
        about_page_count_projects: "Նախագծեր",
        about_page_count_commercials: "Կոմերցիոն",
        about_page_count_houses: "Բնակարաններ",
        about_page_count_workers: "Աշխատակիցներ",
        about_page_btn_contact: "Հետադարձ կապ",
        about_page_btn_houses: "Բնակարաններ",
        about_building_number:'22',
        about_building_partners:'22',
        about_building_customers:'22',

        //Contacts
        contacts_page_title: "Հետադարձ կապ",
        contacts_page_left_subTitle: "Կոնտակտային տվյալներ",
        contacts_page_left_text: "Մենք համոզված ենք, որ շինարարական բիզնեսում:",
        contacts_page_info_phone_title: "Հեռ․",
        contacts_page_info_email_title: "Էլ․ հասցե",
        contacts_page_info_address_title: "Հասցե",
        contacts_page_right_subTitle: "Ուղարկել հաղորդագրություն",
        contacts_page_right_text: "Մենք համոզված ենք, որ շինարարական բիզնեսում:ն",
        contacts_page_placeholder_name: "Անուն, ազգանուն",
        contacts_page_placeholder_email: "Էլ․ հասցե",
        contacts_page_placeholder_phoneNumber: "Հեռ. համար",
        contacts_page_placeholder_message: "Հաղորդագրոթյուն",
        contacts_page_btn_send_message: "Ուղարկել",

        //+Routs
        rout_item_home: "Գլխավոր",
        rout_item_buildings: "Մասնաշենքեր",
        rout_item_title_building: "Մասնաշենք",
        rout_item_building: "Մասնաշենք",
        rout_item_title_floor: "Հարկ",
        rout_item_floor: "Հարկ",
        rout_item_house: "Բնակարան",
        rout_item_office: "Բնակարան",
        rout_item_title_house: "Բնակարան",
        rout_item_title_office: "Office",
        rout_item_title_parking: "Բնակարան",
        rout_item_address_title: "Հասցեն",
        rout_item_filter_title: "Ֆիլտր",
        rout_item_filter_text: "Խմբավորել",
        rout_item_filter_title_choose_house : "Բնակարանների ընտրություն",
        rout_item_calculator_title: "Հաշվիչ",
        rout_item_calculator_text: "Հաշվիչ",

        // //Badge
        // badge_is_available: "Առկա է",
        // badge_is_reserved: "Ամրագրված է",
        // badge_is_sold: "Վաճառված է",

        //Mouse Tooltip
        mouse_info_building: "Շենք",
        mouse_info_floor: "հարկ",
        mouse_info_house: "բնակարան",
        mouse_info_office: "բնակարան-en",
        mouse_info_room: "սենյակ",
        mouse_info_is_available: "առկա է",

        //House Details
        house_right_info_title: "Բնակարան",
        house_right_info_upload_btn: "Ներբեռնել PDF",
        house_checkbox_other_projects: "Այլընտրանքային նախագծեր",
        house_checkbox_identical_houses: "Նմանատիպ բնակարաններ այլ հարկերում",
        house_right_info_cadastral_area: "~ կադաստրային մակերես",
        house_right_info_area: "Ընդհանուր մակերես",
        house_right_info_renovated_checkbox: "Վերանորոգված",
        house_right_info_price_contractual: "Պայմանագրային",
        house_right_info_reserve_now_btn: "Ամրագրել հիմա",
        house_right_info_secondary_market_btn: "Available in secondary market-hy",
        house_right_info_link_text: "Ծանոթացեք հիպոթեքի պայմաններին ",
        house_right_info_link_blue_text: " այստեղ",
        house_right_info_monthly_payment: " միջին ամսական վճար",

        //Units of measurement
        measurement_unit_meter: "մ",
        measurement_unit_dram: "Դ",

        //Modal
        modal_title: "Ամրագրել բնակարան",
        modal_success_message: "Հաջողությամբ կատարվեց",
        modal_error_message: "Հաջողությամբ կատարվեց",
        modal_name_label: "Անուն, ազգանուն",
        modal_email_label: "Էլ․ հասցե",
        modal_phone_label: "ՀԵռ․ համար",
        modal_house_number_label: "Բնակարանի համար",
        modal_btn_cancel: "Չեղարկել",
        modal_btn_close: "Փակել",
        modal_btn_save: "Պահպանել",
        call_modal_title: "заявка на звонок-hy",
        call_time_placeholder: "Other time-hy",
        call_time_picker_placeholder:"Time-hy",

        //Filter
        filter_building_title: "Մասնաշենք",
        filter_projects_title: "Choose complex",
        filter_floors_title: "Հարկեր",
        filter_sort_placeholder: "Դասակարգել ըստ",
        filter_floors_dropdown_btn_cancel: "Չեղարկել",
        filter_floors_dropdown_btn_remember: "Հիշել",
        filter_rooms_count_title: "Ննջասենյակ",
        filter_area_title: "Մակերես",
        filter_min_area_placeholder: "սկսած",
        filter_max_area_placeholder: "մինչև",
        filter_price_title: "Գին",
        filter_min_price_placeholder: "սկսած",
        filter_max_price_placeholder: "մինչև",
        filter_clear_filters: "Չեղարկել",
        filter_showing_count: "Found %d variants in %l buildings",
        filter_open_filter_menu: "Construction button",
        filter_360_btn: "View 360°",
        filter_3d_btn: "Choose from 3D-version",
        filter_show_more_btn: "Show more variants",
        filter_project_areas_count: "Showing %d variant from %l",
        filter_sort_type_rooms_count: "count",
        filter_sort_type_area: "area",
        filter_sort_type_price: "price",

        //Types
        sort_by_price_increase: "Գնի աճման",
        sort_by_price_reduction: "Գնի նավազման",
        sort_by_area_increase: "Մակերեսի աճման",
        sort_by_area_reduction: "Մակերեսի նավազման",
        sort_by_rooms_count_increase: "Սենյակների աճման",
        sort_by_rooms_count_reduction: "Սենյակների նավազման",
        area_type_apartment: 'Բնակարան',
        area_type_office: 'Գրասենյակային տարածք',
        area_type_hotel: 'Հյուրանոց',
        area_type_perking: 'Ավտոկայանատեղի',

        //House Card
        house_card_price_contractual: "Պայմանագրային",
        house_card_area_title: "Մակերես",
        house_card_floor_title: "Հարկ",
        house_card_rooms_title: "Սենյակներ",
        house_card_reverse_btn: "Ամրագրել",
        house_card_reserve: "Reserve",

        //Calculator
        calculator_left_title: "Հիփոթեքի հաշվիչ",
        calculator_hypothec_price_label: "Հիփոթեքի գումարը (ՀՀԴ)",
        calculator_hypothec_price_placeholder: "Հիփոթեքի գումար",
        calculator_hypothec_advancePayment_label: "Կանխավճար",
        calculator_hypothec_advancePayment_placeholder: "Կանխավճար",
        calculator_hypothec_date_label: "Ժամկետ",
        calculator_hypothec_date_placeholder: "Ժամկետ",
        calculator_hypothec_type_label: "Տեսակ",
        calculator_hypothec_type_placeholder: "Տեսակ",
        calculator_repayment_type_label: "Մարման տեսակ",
        calculator_repayment_type_placeholder: "Մարման տեսակ",
        calculator_hypothec_type_mount: "Ամիս",
        calculator_hypothec_type_year: "Տարի",
        calculator_repayment_type_annuity: "Անուիտետ",
        calculator_repayment_type_differentiated: "Դիֆերենցված",
        calculator_bank_title_label: "Բանկ",
        calculator_bank_title_placeholder: "Բանկ",
        calculator_hypothec_percent_label: "Տարեկան տոկոսադրույք (%)",
        calculator_hypothec_percent_placeholder: "Տարեկան տոկոսադրույք",
        calculator_cancel_btn: "Չեղարկել",
        calculator_calculate_btn: "Հաշվել",
        calculator_right_title: "Վճար (ՀՀԴ)",
        calculator_right_box_hypothec_price_title: "Հիփոթեքի գումարը (ՀՀԴ)",
        calculator_right_box_hypothec_date_title: "Ժամկետ",
        calculator_right_box_type_mount: "ամիս",
        calculator_right_box_type_year: "տարի",
        calculator_right_box_hypothec_percent_title: "Տարեկան տոկոսադրույք (%)",
        calculator_right_box_main_price_title: "Մայր գումար",
        calculator_right_box_advance_payment_title: "Կանխավճար",
        calculator_right_box_percent_title: "Տոկոս",
        calculator_right_box_income_tax_title: "Եկամտահարկ",
        calculator_right_box_monthly_payment_title: "Ամսական վճար (ՀՀԴ)",
        calculator_table_column_month: "Ամիս",
        calculator_table_column_percent: "Վճարվող տոկոսագումար",
        calculator_table_main_amount: "Մայր գումար",
        calculator_table_monthly_payment: "Ընդամենը վճարում",

        //Coming Soon
        coming_soon_1 : "Coming",
        coming_soon_2 : "soon",
    },
    ru: {
        //Header
        header_construction: "Construction management",

        //Menu
        menu_about: "About",
        menu_contact: "Contact",
        menu_apartments: "Apartments",
        menu_calculator: "Calculator",

        //Homepage Slider
        homepage_slider_project_view: "View complex",
        homepage_slider_apartment_placeholder: "Apartment type",
        homepage_slider_rooms_count_placeholder: "Rooms count",
        homepage_slider_project_placeholder: "Select project",
        homepage_slider_from: "from",
        homepage_slider_to: "to",
        homepage_slider_button_houses: "Բնակարաններ-en",
        homepage_mobile_address_button: "About project",

        //Homepage About
        homepage_about_box_title: "About company",
        homepage_about_box_description: "We are a Armenian company specialized in construction industry.",
        homepage_about_features_title: "You need get our services to evaluate your company work and time.",
        homepage_about_feature_1: "Modern architectural style.",
        homepage_about_feature_2: "You have a business with 12% investment",
        homepage_about_feature_3: "Served parkings and game zone",
        homepage_about_question: "If you have any question?",
        homepage_about_question_button: "Let’s talk!",
        homepage_about_projects_count: "successful projects",
        homepage_about_years_count: "years of experience",
        homepage_about_prof_count: "profesional speciali",
        homepage_about_sub_title: "We working in",

        //Homepage projects
        homepage_projects_question: "Choose apartament ?",
        homepage_projects_question_link: "Here",
        homepage_projects_about_btn: "About complex",

        //Homepage Futures
        homepage_futures_title_line_1: "Why you need",
        homepage_futures_title_line_2: "turn to us ?",
        homepage_futures_question: "Any question ?",
        homepage_futures_calculate: "Calculate",
        homepage_futures_here: "here",
        homepage_futures_item_title_1: "Cost reduction",
        homepage_futures_item_description_1: "Smart service access helps your apartament expenses",
        homepage_futures_item_title_2: "New approach",
        homepage_futures_item_description_2: "Personal manager and a dedicated support team",
        homepage_futures_item_title_3: "Save time",
        homepage_futures_item_description_3: "With full information on price, addresses and order times",
        homepage_futures_item_title_4: "Modern design",
        homepage_futures_item_description_4: "Personal manager and a dedicated support team",

        //Homepage Contact
        homepage_contact_title: "Եղիր ավելի տեղեկացված-ru",
        homepage_contact_text: "Մենք համոզված ենք, որ շինարարական բիզնեսում լավագույն հատկանիշը  երեք տալ այդգնի և որակի լավագույն տարբերակ-ru",
        homepage_contact_placeholder_email: " Ել․ հասցե-ru",
        homepage_contact_button_send: "Ուղարկել-ru",

        //Footer
        footer_section_security_title: "Անվտանգություն-ru",
        footer_section_privacy: "Գաղտնիություն-ru",
        footer_section_terms_and_conditions: "Ընդհանուր դրույթներ և պայմաններ-ru",
        footer_section_company_title: "Ընկերություն-ru",
        footer_section_about: "Մեր մասին-ru",
        footer_section_filters: "Ֆիլտրեր-ru",
        footer_section_calculator: "Հաշվիչ-ru",
        footer_section_contact_title: "Հաշվիչ-ru",
        footer_section_copyright_text: "© Copyright 2020 Online Supermarket All rights reserved.-ru",
        footer_section_developed_by_text: "Մշակումը ՝",
        footer_section_buildings_title: "Նախագծեր",

        //About
        about_page_count_projects: "Նախագծեր-ru",
        about_page_count_commercials: "Կոմերցիոն-ru",
        about_page_count_houses: "Բնակարաններ-ru",
        about_page_count_workers: "Աշխատակիցներ-ru",
        about_page_btn_contact: "Հետադարձ կապ-ru",
        about_page_btn_houses: "Բնակարաններ-ru",
        about_building_number:'22',
        about_partners_number:'22',
        about_customers_number:'22',

        //Contacts
        contacts_page_title: "Հետադարձ կապ-ru",
        contacts_page_left_subTitle: "Կոնտակտային տվյալներ-ru",
        contacts_page_left_text: "Մենք համոզված ենք, որ շինարարական բիզնեսում:-ru",
        contacts_page_info_phone_title: "Հեռ․-ru",
        contacts_page_info_email_title: "Էլ․ հասցե-ru",
        contacts_page_info_address_title: "Հասցե-ru",
        contacts_page_right_subTitle: "Ուղարկել հաղորդագրություն-ru",
        contacts_page_right_text: "Մենք համոզված ենք, որ շինարարական բիզնեսում:-ru",
        contacts_page_placeholder_name: "Անուն, ազգանուն-ru",
        contacts_page_placeholder_email: "Էլ․ հասցե-ru",
        contacts_page_placeholder_phoneNumber: "Հեռ. համար-ru",
        contacts_page_placeholder_message: "Հաղորդագրոթյուն-ru",
        contacts_page_btn_send_message: "Ուղարկել-ru",

        //Routs
        rout_item_home: "Գլխավոր-ru",
        rout_item_buildings: "Մասնաշենքեր-ru",
        rout_item_title_building: "Մասնաշենք-ru",
        rout_item_building: "Մասնաշենք-ru",
        rout_item_title_floor: "Հարկ-ru",
        rout_item_floor: "Հարկ-ru",
        rout_item_house: "Բնակարան-ru",
        rout_item_office: "Բնակարան-ru",
        rout_item_title_house: "Բնակարան-ru",
        rout_item_title_office: "Office",
        rout_item_title_parking: "Բնակարան-ru",
        rout_item_address_title: "Հասցեն-ru",
        rout_item_filter_title: "Ֆիլտր-ru",
        rout_item_filter_text: "Խմբավորել-ru",
        rout_item_filter_title_choose_house : "Բնակարանների ընտրություն-ru",
        rout_item_calculator_title: "Հաշվիչ-ru",
        rout_item_calculator_text: "Հաշվիչ-ru",

        // //Badge
        // badge_is_available: "Առկա է-ru",
        // badge_is_reserved: "Ամրագրված է-ru",
        // badge_is_sold: "Վաճառված է-ru",

        //Mouse Tooltip
        mouse_info_building: "Շենք-ru",
        mouse_info_floor: "հարկ-ru",
        mouse_info_house: "բնակարան-ru",
        mouse_info_office: "բնակարան-en",
        mouse_info_room: "սենյակ-ru",
        mouse_info_is_available: "առկա է-ru",

        //House Details
        house_right_info_title: "Բնակարան-ru",
        house_right_info_upload_btn: "Ներբեռնել PDF-ru",
        house_checkbox_other_projects: "Այլընտրանքային նախագծեր",
        house_checkbox_identical_houses: "Նմանատիպ բնակարաններ այլ հարկերում",
        house_right_info_cadastral_area: "~ кадастровая площадь",
        house_right_info_area: "Ընդհանուր մակերես",
        house_right_info_renovated_checkbox: "Վերանորոգված-ru",
        house_right_info_price_contractual: "Պայմանագրային-ru",
        house_right_info_reserve_now_btn: "Ամրագրել հիմա-ru",
        house_right_info_secondary_market_btn: "Available in secondary market-ru",
        house_right_info_link_text: "Ծանոթացեք հիպոթեքի պայմաններին -ru",
        house_right_info_link_blue_text: " այստեղ-ru",
        house_right_info_monthly_payment: " միջին ամսական վճար",

        //Units of measurement
        measurement_unit_meter: "մ-ru",
        measurement_unit_dram: "Դ-ru",

        //Modal
        modal_title: "Ամրագրել բնակարան-ru",
        modal_success_message: "Հաջողությամբ կատարվեց-ru",
        modal_error_message: "Հաջողությամբ կատարվեց-ru",
        modal_name_label: "Անուն, ազգանուն-ru",
        modal_email_label: "Էլ․ հասցե-ru",
        modal_phone_label: "ՀԵռ․ համար-ru",
        modal_house_number_label: "Բնակարանի համար-ru",
        modal_btn_cancel: "Չեղարկել-ru",
        modal_btn_close: "Փակել-ru",
        modal_btn_save: "Պահպանել-ru",
        call_modal_title: "заявка на звонок",
        call_time_placeholder: "Other time",
        call_time_picker_placeholder:"Time",

        //Filter
        filter_building_title: "Մասնաշենք-ru",
        filter_projects_title: "Choose complex-ru",
        filter_floors_title: "Հարկեր-ru",
        filter_sort_placeholder: "Դասակարգել ըստ-ru",
        filter_floors_dropdown_btn_cancel: "Չեղարկել-ru",
        filter_floors_dropdown_btn_remember: "Հիշել-ru",
        filter_rooms_count_title: "Ննջասենյակ-ru",
        filter_area_title: "Մակերես-ru",
        filter_min_area_placeholder: "սկսած-ru",
        filter_max_area_placeholder: "մինչև-ru",
        filter_price_title: "Գին-ru",
        filter_min_price_placeholder: "սկսած-ru",
        filter_max_price_placeholder: "մինչև-ru",
        filter_clear_filters: "Չեղարկել-ru",
        filter_showing_count: "Found %d variants in %l buildings-ru",
        filter_open_filter_menu: "Construction button-ru",
        filter_360_btn: "View 360°-ru",
        filter_3d_btn: "Choose from 3D-version-ru",
        filter_show_more_btn: "Show more variants-ru",
        filter_project_areas_count: "Showing %d variant from %l-ru",
        filter_sort_type_rooms_count: "count",
        filter_sort_type_area: "area",
        filter_sort_type_price: "price",

        //Types
        sort_by_price_increase: "Գնի աճման-ru",
        sort_by_price_reduction: "Գնի նավազման-ru",
        sort_by_area_increase: "Մակերեսի աճման-ru",
        sort_by_area_reduction: "Մակերեսի նավազման-ru",
        sort_by_rooms_count_increase: "Սենյակների աճման-ru",
        sort_by_rooms_count_reduction: "Սենյակների նավազման-ru",
        area_type_apartment: 'Բնակարան-ru',
        area_type_office: 'Գրասենյակային տարածք-ru',
        area_type_hotel: 'Հյուրանոց-ru',
        area_type_perking: 'Ավտոկայանատեղի-ru',

        //House Card
        house_card_price_contractual: "Պայմանագրային-ru",
        house_card_area_title: "Մակերես-ru",
        house_card_floor_title: "Հարկ-ru",
        house_card_rooms_title: "Սենյակներ-ru",
        house_card_reverse_btn: "Ամրագրել-ru",
        house_card_reserve: "Reserve-ru",

        //Calculator
        calculator_left_title: "Հիփոթեքի հաշվիչ-ru",
        calculator_hypothec_price_label: "Հիփոթեքի գումարը (ՀՀԴ)-ru",
        calculator_hypothec_price_placeholder: "Հիփոթեքի գումար-ru",
        calculator_hypothec_advancePayment_label: "Կանխավճար-ru",
        calculator_hypothec_advancePayment_placeholder: "Կանխավճար-ru",
        calculator_hypothec_date_label: "Ժամկետ-ru",
        calculator_hypothec_date_placeholder: "Ժամկետ-ru",
        calculator_hypothec_type_label: "Տեսակ-ru",
        calculator_hypothec_type_placeholder: "Տեսակ-ru",
        calculator_hypothec_type_mount: "Ամիս-ru",
        calculator_hypothec_type_year: "Տարի-ru",
        calculator_repayment_type_annuity: "Անուիտետ-ru",
        calculator_repayment_type_differentiated: "Դիֆերենցված-ru",
        calculator_bank_title_label: "Բանկ-ru",
        calculator_bank_title_placeholder: "Բանկ-ru",
        calculator_hypothec_percent_label: "Տարեկան տոկոսադրույք (%)-ru",
        calculator_hypothec_percent_placeholder: "Տարեկան տոկոսադրույք-ru",
        calculator_cancel_btn: "Չեղարկել-ru",
        calculator_calculate_btn: "Հաշվել-ru",
        calculator_right_title: "Վճար (ՀՀԴ)-ru",
        calculator_right_box_hypothec_price_title: "Հիփոթեքի գումարը (ՀՀԴ)-ru",
        calculator_right_box_hypothec_date_title: "Ժամկետ-ru",
        calculator_right_box_type_mount: "ամիս-ru",
        calculator_right_box_type_year: "տարի-ru",
        calculator_right_box_hypothec_percent_title: "Տարեկան տոկոսադրույք (%)-ru",
        calculator_right_box_main_price_title: "Մայր գումար-ru",
        calculator_right_box_advance_payment_title: "Կանխավճար-ru",
        calculator_right_box_percent_title: "Տոկոս-ru",
        calculator_right_box_income_tax_title: "Եկամտահարկ-ru",
        calculator_right_box_monthly_payment_title: "Ամսական վճար (ՀՀԴ)-ru",
        calculator_table_column_month: "Ամիս-ru",
        calculator_table_column_percent: "Վճարվող տոկոսագումար-ru",
        calculator_table_main_amount: "Մայր գումար-ru",
        calculator_table_monthly_payment: "Ընդամենը վճարում-ru",

        //Coming Soon
        coming_soon_1 : "Coming",
        coming_soon_2 : "soon",
    }
}
