import { BUILDINGS_CONSTS } from "../constants";

export const initialState = {
    buildings: [],
    buildingById: null,
    houses: {
        list: [],
        hasMore: false,
        buildingSlug: null,
        floors: [],
        roomsCount: 0,
        minArea: '',
        maxArea: '',
        minPrice: '',
        maxPrice: '',
        sort: 'price',
    },
    viewedHouses: []
};

export default (state = initialState, action) => {

    switch ( action.type ) {
        case BUILDINGS_CONSTS.GET_BUILDINGS:
            return {
                ...state,
                buildings: action.payload,
            };
        case BUILDINGS_CONSTS.GET_BUILDING_BY_ID:
            return {
                ...state,
                buildingById: action.payload,
            };
        case BUILDINGS_CONSTS.CLEAR_BUILDING_BY_ID:
            return {
                ...state,
                buildingById: null,
            };

        case BUILDINGS_CONSTS.GET_HOUSES:
            return {
                ...state,
                houses: {
                    list: action.payload.reset ? action.payload.data : [...state.houses.list, ...action.payload.data],
                    availableCount: action.payload.availableCount,
                    reservedCount: action.payload.reservedCount,
                    hasMore: action.payload.hasMore,
                    buildingSlug: action.payload.buildingSlug,
                    floors: action.payload.floors,
                    roomsCount: action.payload.roomsCount,
                    minArea: action.payload.minArea,
                    maxArea: action.payload.maxArea,
                    minPrice: action.payload.minPrice,
                    maxPrice: action.payload.maxPrice,
                    sort: action.payload.sort,
                    statuses: action.payload.statuses,
                }
            };
        case BUILDINGS_CONSTS.VIEW_HOUSE:
            const list = state.viewedHouses || [];
            return {
                ...state,
                viewedHouses: list.includes(action.payload) ?  list : [...list, action.payload] ,
            };
        default:
            return state;
    }
}
