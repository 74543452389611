// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";
import GoogleMapReact from 'google-map-react';

//import styles
import "./address.scss";
import {ReactComponent as Location} from '../../assets/images/icons/map_ic.svg';

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GOOGLE_MAP_KEY} from "../../configs/variables";
import {DEFAULT_ADDRESS, MAP_DEFAULT_ZOOM} from "../../constants/constants";

function Locations(props) {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    let defaultAddress = props.contact?.location?.latitude && props.contact?.location?.longitude && {
        lat: props.contact?.location?.latitude,
        lng: props.contact?.location?.longitude
    }

    return <div className={`locations-wrapper`}>
        <div className={'map'}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: GOOGLE_MAP_KEY,
                    libraries: 'places'
                }}
                defaultCenter={defaultAddress}
                center={defaultAddress}
                distanceToMouse={() => {
                }}
                defaultZoom={MAP_DEFAULT_ZOOM}
                yesIWantToUseGoogleMapApiInternals={true}
            >
                <Marker lat={defaultAddress?.lat}
                        lng={defaultAddress?.lng}
                />
            </GoogleMapReact>
        </div>
    </div>
}

const Marker = (props) => <div className={`marker selected`}>
    <Location title={''}/>
</div>;

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'contact',
    ])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Locations);
