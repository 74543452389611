// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import {Collapse, Dropdown, Menu} from 'antd';

// Import assets
import '../assets/styles/components/footer.scss'
import {
    ArrowIcon,
    CollapseIcon,
    CollapseLangIcon,
    CompanyIcon,
    FbIcon,
    InstaIcon, LinkedinIcon,
    Logo
} from "../assets/images";

// Import utils

import {getPropsFromState} from "../redux/mapStateToProps";
import {ChangeLanguage, GetProjects} from "../redux/actions";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";
import MapWindow from "../containers/filtersPage/mapWindow/MapWindow";
import {toggleMapWindow} from "../utils/toggleMapWindow";

const {Panel} = Collapse;

function Footer(props) {
    const {contact, staticTexts, projects,} = props

    const phone = contact?.phoneNumber?.replace(/\D/g, '').substring(0, 12)
    const areaCode = phone?.substring(0, 3);
    const code = phone?.substring(3, 5);
    const content = phone?.substring(5, 12);

    const phone2 = contact?.phoneNumber2?.replace(/\D/g, '').substring(0, 12)
    const areaCode2 = phone2?.substring(0, 3);
    const code2 = phone2?.substring(3, 5);
    const content2 = phone2?.substring(5, 12);

    useEffect(() => {
        props.GetProjects()
    }, [])

    function goUp() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const artGroupProject = projects ?  [
        ...projects,
        {
            latitude: contact?.location?.latitude,
            longitude: contact?.location?.longitude,
            title: contact?.address
        }] : []

    return <footer className='footerWrapper'>
        <div className="top-side desktop">
            <div className="col">
                <div className="logo-wrapper">
                    <Link to={getUrlWithLocal('/')}><img src={Logo} alt=""/></Link>
                </div>
                <div className="social-wrapper">
                    <a href={contact?.facebookUrl} className="fb" target={'_blank'}><FbIcon title=''/></a>
                    <a href={contact?.instagramUrl} className="insta" target={'_blank'}><InstaIcon title=''/></a>
                    <a href={contact?.linkedinUrl} className="in" target={'_blank'}><LinkedinIcon title=''/></a>
                </div>

            </div>
            <div className="col">
                <div className="title">{staticTexts?.footer_section_security_title}</div>
                <Link to={getUrlWithLocal(`/privacy-policy`)}>
                    <p>{staticTexts?.footer_section_privacy}</p>
                </Link>
                {/*<Link to={getUrlWithLocal(`/privacy-policy`)}>*/}
                {/*    <p>{staticTexts?.footer_section_terms_and_conditions}</p>*/}
                {/*</Link>*/}
            </div>
            <div className="col">
                <div className="title">{staticTexts?.footer_section_company_title}</div>
                <Link to={getUrlWithLocal(`/about`)}>
                    <p>{staticTexts?.footer_section_about}</p>
                </Link>
                <Link to={getUrlWithLocal(`/custom-page/construction-management`)}>
                    <p>{staticTexts?.header_construction}</p>
                </Link>
            </div>
            <div className="col">
                <div className="title">{staticTexts?.footer_section_buildings_title}</div>
                {
                    projects?.map(item => {
                        return <Link key={item.id} to={getUrlWithLocal(`/buildings/${item?.slug}`)}>
                            <p>{item.title}</p>
                        </Link>
                    })
                }

            </div>
            <div className="col">
                <div className="title">{staticTexts?.footer_section_contact_title}</div>
                <p onClick={toggleMapWindow}>{contact?.address}</p>
                <a href={`tel:${contact?.phoneNumber}`}>
                    <p>{`+${areaCode} (${code}) ${content}`}</p>
                </a>
                <a href={`tel:${contact?.phoneNumber2}`}>
                    <p>{`+${areaCode2} (${code2}) ${content2}`}</p>
                </a>
                <a href={`mailto:${contact?.email}`}>
                    <p>{contact?.email}</p>
                </a>
            </div>
            <div className="go-up" onClick={goUp}>
                <ArrowIcon/>
            </div>
        </div>
        <div className="top-side mobile">
            <div className="logo-wrapper">
                <img src={Logo} alt=""/>
            </div>
            <Collapse accordion
                      expandIconPosition={'right'}
                      expandIcon={(value) => {
                          return <CollapseIcon className={value.isActive ? 'rotate' : ''}/>
                      }}>
                <Panel header={staticTexts?.footer_section_security_title} key="1">
                    <Link to={getUrlWithLocal(`/privacy-policy`)}>
                        <p>{staticTexts?.footer_section_privacy}</p>
                    </Link>
                    <Link to={getUrlWithLocal(`/privacy-policy`)}>
                        <p>{staticTexts?.footer_section_terms_and_conditions}</p>
                    </Link>
                </Panel>
                <Panel header={staticTexts?.footer_section_company_title} key="2">
                    <Link to={getUrlWithLocal(`/about`)}>
                        <p>{staticTexts?.footer_section_about}</p>
                    </Link>
                    <Link to={getUrlWithLocal(`/filter`)}>
                        <p>{staticTexts?.footer_section_filters}</p>
                    </Link>
                    <Link to={getUrlWithLocal(`/calculator`)}>
                        <p>{staticTexts?.footer_section_calculator}</p>
                    </Link>
                </Panel>
                <Panel header={staticTexts?.footer_section_contact_title} key="3">
                    <Link to={getUrlWithLocal('/address')}><p>{contact?.address}</p></Link>
                    <a href={`tel:${contact?.phoneNumber}`}><p>{`+${areaCode} (${code}) ${content}`}</p></a>
                    <a href={`tel:${contact?.phoneNumber2}`}><p>{`+${areaCode2} (${code2}) ${content2}`}</p></a>
                    <a href={`mailto:${contact?.email}`}><p>{contact?.email}</p></a>
                </Panel>
            </Collapse>
            <div className={'top-side-bottom-wrapper'}>
                <div className="social-wrapper">
                    <a href={contact?.facebookUrl} target={'_blank'}><FbIcon title=''/></a>
                    <a href={contact?.instagramUrl} target={'_blank'}><InstaIcon title=''/></a>
                    <a href={contact?.linkedinUrl} target={'_blank'}><LinkedinIcon title=''/></a>
                </div>
            </div>

        </div>
        <div className="bottom-side">
            <div>{staticTexts?.footer_section_copyright_text}</div>
            <div className='developed-by'>{staticTexts?.footer_section_developed_by_text}
                <a href="https://4steps.am/" target="_blank"><CompanyIcon/></a>
            </div>
        </div>
        {contact && <MapWindow projects={artGroupProject}/>}
    </footer>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'projects',
        'contact',
    ])
};

const mapDispatchToProps = {
    ChangeLanguage,
    GetProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
