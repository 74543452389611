// Import packages
import React, {PureComponent} from 'react';

// Import components
import {Helmet} from "react-helmet";

import {defaultTexts} from "../constants/defaultTexts";
// import logo from '../assets/images/logo_footer.svg';

const logo = 'https://app.art-group.am/admin/files/resources/logo.png'

class MetaTags extends PureComponent {

    render() {
        const {title, description, image, url} = this.props;
        const _title = title ? (`${title} | ${defaultTexts.title}`) : defaultTexts.title;
        const _description = description || defaultTexts.description;
        let _image = logo;
        if (image) {
            _image = image;
        }
        const _url = url || window.location.href;
        const {hostname, port, protocol, origin} = window.location;
        const BASE_URL = origin;
        return (<Helmet>
                <title>{_title}</title>
                <meta name="description" content={_description}/>
                <meta property="og:image" content={_image}/>

                <meta property="og:title" content={_title}/>
                <meta property="og:site_name" content='Art-Group'/>
                <meta property="og:description" content={_description}/>

                <meta property="og:url" content={_url}/>
                <meta property="og:image:width" content={600}/>
                <meta property="og:image:height" content={600}/>
                <meta property="og:type" content={'website'}/>

                <link rel="shortcut icon" href={'../assets/images/favicon/favicon.ico'}/>
                <link id="favicon" rel="icon" href={'../assets/images/favicon/favicon.ico'} type="image/x-icon"/>
                <link rel="icon" type="image/png" sizes="16x16" href={'../assets/images/favicon/favicon-16x16.png'}/>
                <link rel="icon" type="image/png" sizes="32x32" href={'../assets/images/favicon/favicon-32x32.png'}/>
                <link rel="apple-touch-icon" sizes="180x180" href={'../assets/images/favicon/apple-touch-icon.png'}/>
                <link rel="android-chrome" sizes="180x180" href={'../assets/images/favicon/apple-touch-icon.png'}/>
                <link rel="icon" type="image/png" href={'../assets/images/favicon/android-chrome-192x192.png'} sizes="192x192"/>
                <link rel="icon" type="image/png" href={'../assets/images/favicon/android-chrome-512x512.png'} sizes="512x512"/>
                <link rel="manifest" href={'../assets/images/favicon/site.webmanifest'}/>

            </Helmet>
        )
    }
}

export default MetaTags;
