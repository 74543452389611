import {
    BuildingAFloor1,
    BuildingAFloor10,
    BuildingAFloor10Select,
    BuildingAFloor11,
    BuildingAFloor11Select,
    BuildingAFloor12,
    BuildingAFloor12Select,
    BuildingAFloor13,
    BuildingAFloor13Select,
    BuildingAFloor14,
    BuildingAFloor14Select,
    BuildingAFloor15,
    BuildingAFloor15Select,
    BuildingAFloor16,
    BuildingAFloor16Select,
    BuildingAFloor1Select,
    BuildingAFloor2,
    BuildingAFloor2Select,
    BuildingAFloor3,
    BuildingAFloor3Select,
    BuildingAFloor4,
    BuildingAFloor4Select,
    BuildingAFloor5,
    BuildingAFloor5Select,
    BuildingAFloor6,
    BuildingAFloor6Select,
    BuildingAFloor7,
    BuildingAFloor7Select,
    BuildingAFloor8,
    BuildingAFloor8Select,
    BuildingAFloor9,
    BuildingAFloor9Select,
    BuildingAParking1,
    BuildingAParking1Select,
    BuildingAParking2,
    BuildingAParking2Select,
    BuildingBFloor1,
    BuildingBFloor10,
    BuildingBFloor10Select,
    BuildingBFloor11,
    BuildingBFloor11Select,
    BuildingBFloor12,
    BuildingBFloor12Select,
    BuildingBFloor13,
    BuildingBFloor13Select,
    BuildingBFloor14,
    BuildingBFloor14Select,
    BuildingBFloor15,
    BuildingBFloor15Select,
    BuildingBFloor16,
    BuildingBFloor16Select,
    BuildingBFloor1Select,
    BuildingBFloor2,
    BuildingBFloor2Select,
    BuildingBFloor3,
    BuildingBFloor3Select,
    BuildingBFloor4,
    BuildingBFloor4Select,
    BuildingBFloor5,
    BuildingBFloor5Select,
    BuildingBFloor6,
    BuildingBFloor6Select,
    BuildingBFloor7,
    BuildingBFloor7Select,
    BuildingBFloor8,
    BuildingBFloor8Select,
    BuildingBFloor9,
    BuildingBFloor9Select,
    BuildingsImageHotel,
    BuildingsImageGhapantsyan,
    BuildingsSelectGhapantsyan,
    BuildingsSelectHotel,
    sold_en,
    sold_hy,
    sold_ru,
    BuildingA2Floor2,
    BuildingA2Floor2Select,
    BuildingsImageGarun,
    BuildingsSelectGarun,
    BuildingA1Floor2,
    BuildingA1Floor2Select,
    BuildingA1Floor3,
    BuildingA1Floor4,
    BuildingA1Floor5,
    BuildingA1Floor6,
    BuildingA1Floor7,
    BuildingA1Floor8,
    BuildingA1Floor9,
    BuildingA1Floor10,
    BuildingA1Floor11,
    BuildingA1Floor12,
    BuildingA1Floor13,
    BuildingA1Floor14,
    BuildingA1Floor15,
    BuildingB1Office2Select,
    BuildingB1Floor2Select,
    BuildingB1Office_2,
    BuildingB1Floor2,
    BuildingB1Floor4,
    BuildingB1Floor3,
    BuildingB1Floor5,
    BuildingB1Floor6,
    BuildingB1Floor7,
    BuildingB1Floor8,
    BuildingB1Floor9,
    BuildingB1Floor10,
    BuildingB1Floor11,
    BuildingB1Floor12,
    BuildingB1Floor13,
    BuildingB1Floor14,
    BuildingB1Floor15,
    BuildingA1Floor3Select,
    BuildingA1Floor5Select,
    BuildingA1Floor4Select,
    BuildingA1Floor6Select,
    BuildingA1Floor7Select,
    BuildingA1Floor8Select,
    BuildingA1Floor9Select,
    BuildingA1Floor10Select,
    BuildingA1Floor11Select,
    BuildingA1Floor12Select,
    BuildingA1Floor13Select,
    BuildingA1Floor14Select,
    BuildingA1Floor15Select,
    BuildingB1Floor3Select,
    BuildingB1Floor4Select,
    BuildingB1Floor5Select,
    BuildingB1Floor6Select,
    BuildingB1Floor7Select,
    BuildingB1Floor8Select,
    BuildingB1Floor9Select,
    BuildingB1Floor10Select,
    BuildingB1Floor11Select,
    BuildingB1Floor12Select,
    BuildingB1Floor13Select,
    BuildingB1Floor14Select,
    BuildingB1Floor15Select,
    BuildingA1Floor16_1Select,
    BuildingA1Floor16_2Select,
    BuildingA1Floor16_1,
    BuildingA1Floor16_2,
    BuildingB1Floor16_1,
    BuildingB1Floor16_2,
    BuildingB1Floor16_1Select,
    BuildingB1Floor16_2Select,
    BuildingB1Parking1,
    BuildingB1Parking2,
    BuildingB1Parking3,
    BuildingB1Parking4Select,
    BuildingB1Parking2Select,
    BuildingB1Parking3Select,
    BuildingB1Office1Select,
    BuildingB1Office_1,
    BuildingsSelectPark,
    BuildingsImagePark,
    BuildingA3Floor3Select,
    BuildingA3Floor4Select,
    BuildingA3Floor5Select,
    BuildingA3Floor1_1Select,
    BuildingA3Floor1_2Select,
    BuildingA3Floor2Select,
    BuildingA3Parking1Select,
    BuildingA3Parking1,
    BuildingA3Floor1_1,
    BuildingA3Floor1_2,
    BuildingA3Floor2,
    BuildingA3Floor3,
    BuildingA3Floor4,
    BuildingA3Floor5,
    BuildingA1Office1Select,
    BuildingA1Office2Select, BuildingA1Office_1, BuildingA1Office_2
} from "../assets/images";
import React from "react";

export const BUILDINGS_KEYS = {
    A: "A",
    A1: "A1",
    B: "B",
    B1: "B1",
    A2: "A2",
    A3: "A3"
}

export const floorsImages = {
    "A_-1": BuildingAParking1,
    "A_-2": BuildingAParking2,
    "B_-1": BuildingAParking1,
    "B_-2": BuildingAParking2,
    A_1: BuildingAFloor1,
    A_2: BuildingAFloor2,
    A_3: BuildingAFloor3,
    A_4: BuildingAFloor4,
    A_5: BuildingAFloor5,
    A_6: BuildingAFloor6,
    A_7: BuildingAFloor7,
    A_8: BuildingAFloor8,
    A_9: BuildingAFloor9,
    A_10: BuildingAFloor10,
    A_11: BuildingAFloor11,
    A_12: BuildingAFloor12,
    A_13: BuildingAFloor13,
    A_14: BuildingAFloor14,
    A_15: BuildingAFloor15,
    A_16: BuildingAFloor16,
    B_1: BuildingBFloor1,
    B_2: BuildingBFloor2,
    B_3: BuildingBFloor3,
    B_4: BuildingBFloor4,
    B_5: BuildingBFloor5,
    B_6: BuildingBFloor6,
    B_7: BuildingBFloor7,
    B_8: BuildingBFloor8,
    B_9: BuildingBFloor9,
    B_10: BuildingBFloor10,
    B_11: BuildingBFloor11,
    B_12: BuildingBFloor12,
    B_13: BuildingBFloor13,
    B_14: BuildingBFloor14,
    B_15: BuildingBFloor15,
    B_16: BuildingBFloor16,
    A2_1: BuildingAFloor1,
    A2_2: BuildingA2Floor2,
    A2_3: BuildingA2Floor2,
    A2_4: BuildingAFloor4,
    A2_5: BuildingAFloor5,
    A2_6: BuildingAFloor6,
    A2_7: BuildingAFloor7,
    A2_8: BuildingAFloor8,
    A2_9: BuildingAFloor9,
    A2_10: BuildingAFloor10,
    "A1_-1": BuildingA1Office_1,
    "A1_-2": BuildingB1Parking1,
    "A1_-3": BuildingB1Parking2,
    "A1_-4": BuildingB1Parking3,
    "B1_-1": BuildingB1Office_1,
    "B1_-2": BuildingB1Parking1,
    "B1_-3": BuildingB1Parking2,
    "B1_-4": BuildingB1Parking3,
    A1_1: BuildingA1Office_2,
    A1_2: BuildingA1Floor2,
    A1_3: BuildingA1Floor3,
    A1_4: BuildingA1Floor4,
    A1_5: BuildingA1Floor5,
    A1_6: BuildingA1Floor6,
    A1_7: BuildingA1Floor7,
    A1_8: BuildingA1Floor8,
    A1_9: BuildingA1Floor9,
    A1_10: BuildingA1Floor10,
    A1_11: BuildingA1Floor11,
    A1_12: BuildingA1Floor12,
    A1_13: BuildingA1Floor13,
    A1_14: BuildingA1Floor14,
    A1_15: BuildingA1Floor15,
    A1_16_1: BuildingA1Floor16_1,
    A1_16_2: BuildingA1Floor16_2,
    B1_1: BuildingB1Office_2,
    B1_2: BuildingB1Floor2,
    B1_3: BuildingB1Floor3,
    B1_4: BuildingB1Floor4,
    B1_5: BuildingB1Floor5,
    B1_6: BuildingB1Floor6,
    B1_7: BuildingB1Floor7,
    B1_8: BuildingB1Floor8,
    B1_9: BuildingB1Floor9,
    B1_10: BuildingB1Floor10,
    B1_11: BuildingB1Floor11,
    B1_12: BuildingB1Floor12,
    B1_13: BuildingB1Floor13,
    B1_14: BuildingB1Floor14,
    B1_15: BuildingB1Floor15,
    B1_16_1: BuildingB1Floor16_1,
    B1_16_2: BuildingB1Floor16_2,
    "A3_-1": BuildingA3Parking1,
    A3_1_1: BuildingA3Floor1_1,
    A3_1_2: BuildingA3Floor1_2,
    A3_2: BuildingA3Floor2,
    A3_3: BuildingA3Floor3,
    A3_4: BuildingA3Floor4,
    A3_5: BuildingA3Floor5,
}

export const floorsSelect = {
    "A_-1": BuildingAParking1Select,
    "A_-2": BuildingAParking2Select,
    "B_-1": BuildingAParking1Select,
    "B_-2": BuildingAParking2Select,
    A_1: BuildingAFloor1Select,
    A_2: BuildingAFloor2Select,
    A_3: BuildingAFloor3Select,
    A_4: BuildingAFloor4Select,
    A_5: BuildingAFloor5Select,
    A_6: BuildingAFloor6Select,
    A_7: BuildingAFloor7Select,
    A_8: BuildingAFloor8Select,
    A_9: BuildingAFloor9Select,
    A_10: BuildingAFloor10Select,
    A_11: BuildingAFloor11Select,
    A_12: BuildingAFloor12Select,
    A_13: BuildingAFloor13Select,
    A_14: BuildingAFloor14Select,
    A_15: BuildingAFloor15Select,
    A_16: BuildingAFloor16Select,
    B_1: BuildingBFloor1Select,
    B_2: BuildingBFloor2Select,
    B_3: BuildingBFloor3Select,
    B_4: BuildingBFloor4Select,
    B_5: BuildingBFloor5Select,
    B_6: BuildingBFloor6Select,
    B_7: BuildingBFloor7Select,
    B_8: BuildingBFloor8Select,
    B_9: BuildingBFloor9Select,
    B_10: BuildingBFloor10Select,
    B_11: BuildingBFloor11Select,
    B_12: BuildingBFloor12Select,
    B_13: BuildingBFloor13Select,
    B_14: BuildingBFloor14Select,
    B_15: BuildingBFloor15Select,
    B_16: BuildingBFloor16Select,
    A2_1: BuildingAFloor1Select,
    A2_2: BuildingA2Floor2Select,
    A2_3: BuildingA2Floor2Select,
    A2_4: BuildingAFloor4Select,
    A2_5: BuildingAFloor5Select,
    A2_6: BuildingAFloor6Select,
    A2_7: BuildingAFloor7Select,
    A2_8: BuildingAFloor8Select,
    A2_9: BuildingAFloor9Select,
    A2_10: BuildingAFloor10Select,



    A1_1: BuildingA1Office2Select,
    A1_2: BuildingA1Floor2Select,
    A1_3: BuildingA1Floor3Select,
    A1_4: BuildingA1Floor4Select,
    A1_5: BuildingA1Floor5Select,
    A1_6: BuildingA1Floor6Select,
    A1_7: BuildingA1Floor7Select,
    A1_8: BuildingA1Floor8Select,
    A1_9: BuildingA1Floor9Select,
    A1_10: BuildingA1Floor10Select,
    A1_11: BuildingA1Floor11Select,
    A1_12: BuildingA1Floor12Select,
    A1_13: BuildingA1Floor13Select,
    A1_14: BuildingA1Floor14Select,
    A1_15: BuildingA1Floor15Select,
    A1_16_1: BuildingA1Floor16_1Select,
    A1_16_2: BuildingA1Floor16_2Select,


    B1_1: BuildingB1Office2Select,
    B1_2: BuildingB1Floor2Select,
    B1_3: BuildingB1Floor3Select,
    B1_4: BuildingB1Floor4Select,
    B1_5: BuildingB1Floor5Select,
    B1_6: BuildingB1Floor6Select,
    B1_7: BuildingB1Floor7Select,
    B1_8: BuildingB1Floor8Select,
    B1_9: BuildingB1Floor9Select,
    B1_10: BuildingB1Floor10Select,
    B1_11: BuildingB1Floor11Select,
    B1_12: BuildingB1Floor12Select,
    B1_13: BuildingB1Floor13Select,
    B1_14: BuildingB1Floor14Select,
    B1_15: BuildingB1Floor15Select,
    B1_16_1: BuildingB1Floor16_1Select,
    B1_16_2: BuildingB1Floor16_2Select,
    A3_1_1: BuildingA3Floor1_1Select,
    A3_1_2: BuildingA3Floor1_2Select,
    A3_2: BuildingA3Floor2Select,
    A3_3: BuildingA3Floor3Select,
    A3_4: BuildingA3Floor4Select,
    A3_5: BuildingA3Floor5Select,


    "A1_-1": BuildingA1Office1Select,
    "A1_-2": BuildingB1Parking2Select,
    "A1_-3": BuildingB1Parking3Select,
    "A1_-4": BuildingB1Parking4Select,
    "B1_-1": BuildingB1Office1Select,
    "B1_-2": BuildingB1Parking2Select,
    "B1_-3": BuildingB1Parking3Select,
    "B1_-4": BuildingB1Parking4Select,
    "A3_-1": BuildingA3Parking1Select,
}
export const PARKING_COUNTS = {
    '610250a78ec7caa8a3dbfe5c': "6102507f8ec7caa8a3dbfd56",
    '610250bf8ec7caa8a3dbfef7': "610250928ec7caa8a3dbfdce",


    //Garun A1 => B1
    "633c0ab44e86122ad1ba58f3": "633c0ae54e86122ad1ba5960", // parking -2
    "633c0acc4e86122ad1ba5911": "633c0af04e86122ad1ba597e", // parking -3
    "6358fe5a4e86122ad1e0b656": "6358fe774e86122ad1e0b67f", // parking -4
};

export const HYPOTHEC_TERM_TYPES = {
    MONTH: 'calculator_hypothec_type_mount',
    YEAR: 'calculator_hypothec_type_year',
}

export const REPAYMENT_TYPES = {
    ANNUITY: 'calculator_repayment_type_annuity',
    DIFFERENTIATED: 'calculator_repayment_type_differentiated',
}

export const DEFAULT_ADDRESS = {
    lat: 40.208370,
    lng: 44.525650
};
export const MAP_DEFAULT_ZOOM = 15;


export const HOUSE_BADGES = {
    fixed: {
        key: "6012c054b88dc494783275c5",
        title: "Ամրագրված է"
    },
    sold: {
        key: "6012c054b88dc494783275c6",
        title: "Վաճառված է",
    },
    available: {
        key: "600ab6e5e503271f1c8ebcd0",
        title: "Առկա է"
    },
};

export const SOLD_IMAGES = {
    hy: sold_hy,
    ru: sold_ru,
    en: sold_en,
};

export const ROOM_COUNTS = [1, 2, 2.5, 3, 4];

export const RESERVE_MODAL_TITLE_TYPES = {
    success: "success",
    error: "error",
    title: "title",
}


export const MIN_FLOOR = 1;
export const MAX_FLOOR = 16;

export const AVAILABLE_BADGE_ID = "600ab6e5e503271f1c8ebcd0";
export const RESERVE_BADGE_ID = "6012c054b88dc494783275c5";

export const AREAS_SORT_TYPES = {
    PRICE_UP: "price",
    PRICE_DOWN: "-price",
    AREA_UP: "area",
    AREA_DOWN: "-area",
    ROOM_COUNTS_UP: "roomsCount",
    ROOM_COUNTS_DOWN: "-roomsCount",
}

export const PROJECTS_CONSTS = {
    "Ghapantsian": {
        img: BuildingsImageGhapantsyan,
        svg: <BuildingsSelectGhapantsyan/>,
        floor_count: '16',
        parking_count: '2',
        parking_first_floor: '-1',
        office_floor: '2',
        office_fistFloor: '1',
        building_name: 'A',
        building_name_second: 'B',
        name: "Ghapantsian",
        renovatedPrice: 55000,
        renovationStartingFloorNumber: 3,
    },
    "Garun": {
        img: BuildingsImageGarun,
        svg: <BuildingsSelectGarun/>,
        floor_count: '16_2',
        parking_count: '3',
        parking_first_floor: '-2',
        office_floor: '1',
        office_fistFloor: '-1',
        building_name: 'B1',
        name: "Garun",
        renovatedPrice: 80000,
        renovationStartingFloorNumber: 2,
    },
    "Hotel": {
        img: BuildingsImageHotel,
        svg: <BuildingsSelectHotel/>,
        floor_count: '8',
        parking_first_floor: '-1',
        parking_count: '1',
        office_floor: '1',
        office_fistFloor: '1',
        building_name: 'A2',
        name: "Hotel"
    },
    "Park": {
        img: BuildingsImagePark,
        svg: <BuildingsSelectPark/>,
        floor_count: '5',
        parking_first_floor: '-1',
        parking_count: '1',
        office_floor: '1',
        building_name: 'A3',
        name: "Park"
    },
}

export const BANKS= {
    INECOBANK: "INECOBANK",
    EVOCABANK: "EVOCABANK",
}
export const BUILDINGS_360_LINKS = {
    "Ghapantsian": null,
    "Garun": {
        1: 'https://360stories.com/armenia/point/yerevan-23-5',
        2: 'https://360stories.com/armenia/point/yerevan-23-5',
        3: 'https://360stories.com/armenia/point/yerevan-23-5',
        4: 'https://360stories.com/armenia/point/yerevan-23-5',
        5: 'https://360stories.com/armenia/point/yerevan-23-5',
        6: 'https://360stories.com/armenia/point/yerevan-40',
        7: 'https://360stories.com/armenia/point/yerevan-40',
        8: 'https://360stories.com/armenia/point/yerevan-40',
        9: 'https://360stories.com/armenia/point/yerevan-40',
        10: 'https://360stories.com/armenia/point/yerevan-40',
        11: 'https://360stories.com/armenia/point/yerevan-40',
        12: 'https://360stories.com/armenia/point/yerevan-56-5',
        13: 'https://360stories.com/armenia/point/yerevan-56-5',
        14: 'https://360stories.com/armenia/point/yerevan-56-5',
        15: 'https://360stories.com/armenia/point/yerevan-56-5',
        16: 'https://360stories.com/armenia/point/yerevan-56-5',
        '16_1': 'https://360stories.com/armenia/point/yerevan-56-5',
        '16_2': 'https://360stories.com/armenia/point/yerevan-56-5',
    },
    "Hotel": null,
    "Park": null,
}
