// import packages
import React from "react";
import {connect} from "react-redux";

// Import assets
import "./futures.scss"

// Import Components

// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {Cost, Design, NewApproach, SaveTime} from "../../../assets/images";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../../utils/getUrlWithLocal";


function Futures({staticTexts}) {
    return <div className={`AboutFutures`}>
        <span className="blur-circle"/>
        <span className="blur-circle right"/>
        <div className="header">
            <div className={'header__title'}>
                <span>{staticTexts?.homepage_futures_title_line_1}</span>
                <span>{staticTexts?.homepage_futures_title_line_2}</span>
            </div>
            <div className={'header__question'}>
                {staticTexts?.homepage_futures_question}&nbsp;
                <span>{staticTexts?.homepage_futures_calculate} <Link to={getUrlWithLocal(`/contact`)} className={'link'}>{staticTexts?.homepage_futures_here}</Link></span>
            </div>
        </div>
        <div className="futures-list">
            <div className="futures-list__item">
                <Cost/>
                <span className="item_title">{staticTexts?.homepage_futures_item_title_1}</span>
                <span className="item_description">{staticTexts?.homepage_futures_item_description_1}</span>
            </div>
            <div className="futures-list__item">
                <NewApproach/>
                <span className="item_title">{staticTexts?.homepage_futures_item_title_2}</span>
                <span className="item_description">{staticTexts?.homepage_futures_item_description_2}</span>
            </div>
            <div className="futures-list__item">
                <SaveTime/>
                <span className="item_title">{staticTexts?.homepage_futures_item_title_3}</span>
                <span className="item_description">{staticTexts?.homepage_futures_item_description_3}</span>
            </div>
            <div className="futures-list__item">
                <Design/>
                <span className="item_title">{staticTexts?.homepage_futures_item_title_4}</span>
                <span className="item_description">{staticTexts?.homepage_futures_item_description_4}</span>
            </div>
        </div>
        <div className={'mob-question'}>
            {staticTexts?.homepage_futures_question}&nbsp;
            <span>{staticTexts?.homepage_futures_calculate} <span className={'link'}>{staticTexts?.homepage_futures_here}</span></span>
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, ['staticTexts']);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Futures);
