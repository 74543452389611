// import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Slider from "react-slick";
import {Link} from "react-router-dom";

//Import styles
import "./mainSlider.scss";

//Import Components
import HomeFilter from "./filter/HomeFilter";
import {
    ArrowIcon, MobileCalcIcon, MobileFilterIcon,
    MobileLeftArrIcon, MobileMapIcon, MobileRightArrIcon
} from "../../../assets/images";

// Import utils
import {generateImageMediaUrl, generateSliderMediaUrl} from "../../../utils/generateMediaUrl";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {getUrlWithLocal} from "../../../utils/getUrlWithLocal";
import ProgressiveImage from "react-progressive-graceful-image";
import {resizeImage} from "../../../utils/resizeImage";
import {PrimaryButton} from "../../../components/ui-elements/buttons/Buttons";
import MapWindow from "../../filtersPage/mapWindow/MapWindow";
import { toggleMapWindow } from "../../../utils/toggleMapWindow";

class MainSlider extends Component {
    constructor() {
        super();
        this.state = {
            slideIndex: 0,
            loadedImg: false,
        };
        this.ref = React.createRef();

    }

    nextSlide = (index) => {
        this.setState({
            slideIndex:index + 1,
        });
        this.slider.slickNext();
    };

    prevSlide = (index) => {
        this.setState({
            slideIndex:index + 1,
        });
        this.slider.slickPrev();
    };

    render() {
        const {mainSlider, staticTexts, projects,areaData,} = this.props;

        const settings = {
            dots: false,
            arrows: false,
            autoplay: false,
            pauseOnHover: false,
            swipeToSlide:false,
            swipe: true,
            infinite: true,
            ref: slider => (this.slider = slider),
            slidesToShow: 1,
            slidesToScroll: 1,
            fade:true,
        };

        return !!mainSlider && !!mainSlider.length && <div className="image-wrapper" ref={this.props.refProp}>
            <div className={'main-slider-items'}>
                <div className={'main-slider-inner-part'}>
                    {<Slider {...settings}>
                        {mainSlider?.length && mainSlider?.map((item) => {
                            // console.log(item?.project?.slug ,'item')
                            let mobileImg = item?.mediaMain?.path?.split('.')
                            mobileImg?.splice(mobileImg.length - 1, 0, "-sm")
                            mobileImg?.splice(mobileImg.length - 1, 0, ".")

                            const orientation = window.screen?.orientation?.type;
                            return <div key={item.id} className={`slider-item`} ref={this.img}>
                                <div className="overlay"/>
                                <Link to={getUrlWithLocal(`/buildings/${item?.project?.slug}`)} className={'mobile-img-wrapper'}>
                                    <ProgressiveImage src={generateSliderMediaUrl(item?.mediaMain?.path)}
                                                      placeholder={resizeImage(generateImageMediaUrl(item?.mediaMain?.path))}>
                                        {(src, loading) => (
                                            <img
                                                className={`image${loading ? " loading" : " loaded"}`}
                                                src={src}
                                                alt=""
                                                width="300"
                                                height="300"
                                            />
                                        )}
                                    </ProgressiveImage>
                                </Link>
                                <div className={'mobile-block'} >
                                    <div className={'blocks-title'}>
                                        {item?.project?.title}
                                    </div>
                                    <Link to={getUrlWithLocal(`/buildings/${item?.project?.slug}`)} className={'about_block'}>
                                        {staticTexts?.homepage_mobile_address_button}
                                    </Link>
                                    <div className={'address_block'} onClick={toggleMapWindow}>
                                       <MobileMapIcon/> {item?.project?.address}
                                    </div>
                                    <div className={'second-block'}>
                                        <div className={'icon-block'}>
                                            <Link to={
                                            {
                                                // state: {statuses:['600ab6e5e503271f1c8ebcd0']},
                                                pathname: getUrlWithLocal("/filter")
                                            }
                                            } className="icon_wrapper">
                                                <MobileFilterIcon/>
                                            </Link>
                                            <Link to={getUrlWithLocal("/calculator")} className="calc-icon_wrapper">
                                                <MobileCalcIcon/>
                                            </Link>
                                        </div>
                                        <PrimaryButton link={getUrlWithLocal("/filter")}
                                                       title={staticTexts?.homepage_slider_button_houses.replace
                                                       ('%d', areaData?.availableCount || 0)}/>
                                    </div>
                                    {
                                        <div className={'buttons_block'}>
                                            <button className={`slider-btn  prev-btn`}
                                                    onClick={() => this.prevSlide(this.state.slideIndex)}
                                            >
                                                <MobileLeftArrIcon/>
                                            </button>
                                            <button
                                                className={`slider-btn next-btn`}
                                                onClick={() => this.nextSlide(this.state.slideIndex)}>
                                                <MobileRightArrIcon/>
                                            </button>
                                        </div>
                                    }
                            </div>
                            </div>
                        })}
                    </Slider>}
                    {!!projects?.length && <div className="projects-wrapper">
                        {projects.map((item) => {
                            return <Link to={getUrlWithLocal(`/buildings/${item?.slug}`)} className="project-item"
                                         key={item?.id}>
                                <div className="project-item__inner">
                                    <ProgressiveImage src={generateSliderMediaUrl(item?.mediaMain?.path)}
                                                      placeholder={resizeImage(generateImageMediaUrl(item?.mediaMain?.path))}>
                                        {(src, loading) => (
                                            <img
                                                className={`image${loading ? " loading" : " loaded"}`}
                                                src={src}
                                                alt=""
                                                width="400"
                                                height="320"
                                            />
                                        )}
                                    </ProgressiveImage>
                                    {/*<img src={generateSliderMediaUrl(item?.mediaMain?.path)}/>*/}
                                    <div className="project-item-info"/>
                                    <div className="content">
                                        <div className="title">{item?.title}</div>
                                        <div className="more">{staticTexts?.homepage_slider_project_view}
                                            <ArrowIcon/></div>
                                    </div>
                                </div>
                            </Link>
                        })}
                    </div>}
                </div>
                <HomeFilter
                    searchBoxIsSticky={this.props.searchBoxIsSticky}
                    stickyRowIsOpen={this.props.stickyRowIsOpen}
                    toggleStickyRow={this.props.toggleStickyRow}
                />
                <MapWindow projects={projects}/>
            </div>
        </div>
    }
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'mainSlider',
        'contact',
        'projects',
        'areaData',
    ])
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainSlider);
