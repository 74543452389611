import {CONTACT_CONSTS} from "../constants";

export const initialState = {
    contact: {},
};

export default (state = initialState, action) => {


    switch (action.type) {
        case CONTACT_CONSTS.GET_CONTACT:
            return {
                ...state,
                contact: action.payload,
            };

        default:
            return state;
    }
}
