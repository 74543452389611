// import packages
import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {LoadingOutlined} from '@ant-design/icons'
import {isEmail} from "validator";
import {Link} from "react-router-dom";

// Import assets
import "./contact.scss"
import {
    DarkFbIcon, DarkInstaIcon, DarkLinkedinIcon,
    EmailIcon,
    LocationIcon,
    PhoneIcon,
    TickIcon,
} from "../../assets/images";

// Import Components
import {InputGroup} from "../../components/ui-elements/input-group/inputGroup";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {SendMessage} from "../../redux/actions";
import {generateFileMediaUrl} from "../../utils/generateMediaUrl";
import MapWindow from "../filtersPage/mapWindow/MapWindow";
import { toggleMapWindow } from "../../utils/toggleMapWindow";


function Contact(props) {
    const [tick, setTick] = useState(false)

    const [fieldsData, setFieldsData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    })
    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    })

    useEffect(  () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])
    const showTick = () => {
        setTimeout(
            () => {
                setTick(false)
                setErrors({
                    name: "",
                    email: "",
                    phone: "",
                    message: "",
                })
                setFieldsData({
                    name: "",
                    email: "",
                    phone: "",
                    message: "",
                })
            },
            2000,
        )
    }

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    function validate() {
        const errors = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email))
            ) {
                errors[key] = true;
                result = false;
            }

        })
        setErrors(errors);
        return result;
    }

    const sendMessage = () => {

        if (validate()) {

            props.SendMessage(fieldsData).then(() => {
                setTick(true)
                showTick()
            })
        }
    }
    const {requestLoading, contact, staticTexts,projects} = props

    const artGroupProject = [
        ...projects,
        {
            latitude: contact?.location?.latitude,
            longitude: contact?.location?.longitude,
            title: contact?.address
        }]

    return <div className={'ContactWrapper'}>
        <div className="ContactImageWrapper"
             style={{backgroundImage: `url(${generateFileMediaUrl(contact?.backgroundImage?.path)})`}}>
            <div className={'transparent-bg'}>
                <h1 className={'contact-main-title'}>{staticTexts?.contacts_page_title}</h1>
            </div>
        </div>
        <div className={'ContactMainContent'}>
            <div className="LeftSide">
                <h1 className={'subtitle'}>{staticTexts?.contacts_page_left_subTitle}</h1>
                <div className={'description'}>{staticTexts?.contacts_page_left_text}</div>
                <div className={'line'}/>
                <div className={'contact-items'}>
                    <a href={`tel:${contact?.phoneNumber}`} className={'item-card'}>
                        <div className={'icon-wrapper'}>
                            <PhoneIcon/>
                        </div>
                        <div className={'item-card-content'}>
                            <div
                                className={'item-card-content-title'}>{staticTexts?.contacts_page_info_phone_title}</div>
                            <div
                                className={'item-card-content-text'}>{contact?.phoneNumber || ""}</div>
                        </div>
                    </a>
                    <a href={`mailto:${contact?.email}`} className={'item-card'}>
                        <div className={'icon-wrapper'}>
                            <PhoneIcon/>
                        </div>
                        <div className={'item-card-content'}>
                            <div
                                className={'item-card-content-title'}>{staticTexts?.contacts_page_info_phone_title}</div>
                            <div
                                className={'item-card-content-text'}>{contact?.phoneNumber2 || ""}</div>
                        </div>
                    </a>
                </div>
                <div className={'line'}/>
                <div className={'contact-items'}>
                    <a href={`mailto:${contact?.email}`} className={'item-card'}>
                        <div className={'icon-wrapper'}>
                            <EmailIcon/>
                        </div>
                        <div className={'item-card-content'}>
                            <div
                                className={'item-card-content-title'}>{staticTexts?.contacts_page_info_email_title}</div>
                            <div className={'item-card-content-text'}>{contact?.email}</div>
                        </div>
                    </a>

                    <div onClick={toggleMapWindow} className={'item-card'}>
                        <div className={'icon-wrapper'}>
                            <LocationIcon/>

                        </div>
                        <div className={'item-card-content'}>
                            <div className={'item-card-content-title'}>{staticTexts?.contacts_page_info_address_title}</div>
                            <div className={'item-card-content-text'}>{contact?.address}</div>
                        </div>
                    </div>
                </div>

                <div className={'line'}/>
                <div className="social-wrapper">
                    <a href={contact?.facebookUrl} target={'_blank'}><DarkFbIcon title=''/></a>
                    <a href={contact?.instagramUrl} target={'_blank'}><DarkInstaIcon title=''/></a>
                    <a href={contact?.linkedinUrl} target={'_blank'}><DarkLinkedinIcon title=''/></a>
                </div>
            </div>
            <div className="RightSide">
                <h1 className={'subtitle'}>{staticTexts?.contacts_page_right_subTitle}</h1>
                <div className={'description'}>{staticTexts?.contacts_page_right_text}</div>
                <div className={'fields-wrapper'}>
                    <div className={'fields-horizontal'}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.name}
                            error={errors.name}
                            name={'name'}
                            maxLength={100}
                            label={staticTexts?.contacts_page_placeholder_name}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.email}
                            error={errors.email}
                            name={'email'}
                            maxLength={50}
                            label={staticTexts?.contacts_page_placeholder_email}
                            onChange={getInputValues}
                        />
                    </div>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.phone}
                        error={errors.phone}
                        name={'phone'}
                        maxLength={30}
                        label={staticTexts?.contacts_page_placeholder_phoneNumber}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'textarea'}
                        type={"text"}
                        value={fieldsData.message}
                        error={errors.message}
                        name={'message'}
                        label={staticTexts?.contacts_page_placeholder_message}
                        onChange={getInputValues}
                    />
                    <button className='PrimaryButton'
                            onClick={() => {
                                if (!requestLoading && sendMessage) {
                                    sendMessage();
                                }
                            }}
                    >
                        {staticTexts?.contacts_page_btn_send_message}
                        {
                            requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                : <TickIcon title={''} className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                        }
                    </button>
                </div>
            </div>
        </div>
        {contact && <MapWindow projects={artGroupProject}/>}
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'requestLoading',
    'staticTexts',
    'about',
    'contact',
    'projects'
]);

const mapDispatchToProps = {
    SendMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
