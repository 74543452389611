// import packages
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Import assets
import "./filters.scss"

// Import Components
import { InputGroup } from "../../../components/ui-elements/input-group/inputGroup";
import { PrimaryButton } from "../../../components/ui-elements/buttons/Buttons";
import { Checkbox, Dropdown, Menu, Radio, Slider } from "antd";
import { ArrowIcon, ClearIcon } from "../../../assets/images";

// Import utils
import { getPropsFromState } from "../../../redux/mapStateToProps";
import { AVAILABLE_BADGE_ID, MAX_FLOOR, MIN_FLOOR, ROOM_COUNTS } from "../../../constants/constants";
import { GetAreas} from "../../../redux/actions";
import { AREA_TYPES } from "../../../constants/constType";
import { priceWithCommas } from "../../../utils/helperFunctions";


function Filters (props) {

    const {
        filters,
        changeFilter,
        clearFilters,
        toggleMenu,
        showMenu,

        //redux props.
        projects, areaData, staticTexts, badges, initialAreaData,
    } = props;

    //constants
    const MIN_PRICE = initialAreaData?.minPrice;
    const MAX_PRICE = Math.ceil(initialAreaData?.maxPrice / 10000) * 10000;
    const MIN_AREA = initialAreaData?.minArea;
    const MAX_AREA = initialAreaData?.maxArea;

    const avaiableBadge = badges?.find(badge => badge?.id === AVAILABLE_BADGE_ID)

    //hooks
    // const [mounted, setMounted] = useState(false)
    const [tempData, setTempData] = useState({
        minPrice: filters.minPrice || MIN_PRICE,
        maxPrice: filters.maxPrice || MAX_PRICE,
        minArea: MIN_AREA,
        maxArea: MAX_AREA,
        minFloor: MIN_FLOOR,
        maxFloor: MAX_FLOOR,
    })

    // useEffect(() => {
    //     setMounted(true)
    // }, [])

    const onChangeSliderTempData = (value, type) => {
        setTempData({
            ...tempData,
            [`min${type}`]: value[0],
            [`max${type}`]: value[1],
        })
    }

    /*
        useEffect(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });

            const {minPrice, maxPrice, projects, roomsCount, types, statuses} = location?.state || {}
            // console.log(location?.state, 'location?.state');
            const _filters = {...filters}

            minPrice && (_filters.minPrice = minPrice);
            maxPrice && (_filters.maxPrice = maxPrice);
            roomsCount && (_filters.roomsCount = roomsCount);
            types?.length && (_filters.types = types);
            projects && (_filters.projects = projects);
            statuses?.length && (_filters.statuses = statuses);

            setFilters({...filters, ..._filters});
            delete _filters.floors

            !!filters.statuses.length && (_filters.statuses = filters.statuses);

            !_filters.roomsCount?.length && (delete _filters.roomsCount)
            _filters.minPrice === MIN_PRICE && (delete _filters.minPrice)
            _filters.maxPrice === MAX_PRICE && (delete _filters.maxPrice)
            // console.log(_filters, '_filters');
            GetAreaSearch(_filters)
        }, [])
    */

    function onAfterChangePrice (values) {
        changeFilter({
            minPrice: values[0] !== MIN_PRICE ? values[0] : undefined,
            maxPrice: values[1] !== MAX_PRICE ? values[1] : undefined
        })
    }

    function onAfterChangeArea (values) {
        changeFilter({
            minArea: values[0] !== MIN_AREA ? values[0] : undefined,
            maxArea: values[1] !== MAX_AREA ? values[1] : undefined
        })
    }

    function onAfterChangeFloor (values) {
        const floors = []
        if (values[0] !== MIN_FLOOR || values[1] !== MAX_FLOOR) {
            for ( let i = values[0]; i <= values[1]; i++ ) {
                floors.push(i)
            }
        }
        changeFilter({
            floors
        })
    }

    function getSelectValue (name, value) {
        const changedFilters = {
            [name]: value
        }

        // console.log('changedFilters', changedFilters)
        changeFilter(changedFilters)
    }

    function getStatusValue (id) {
        const statuses = !filters?.statuses?.includes(id)
            ? filters?.statuses?.concat(id)
            : filters?.statuses?.filter(item => item !== id)
        changeFilter({ statuses })
    }

    function resetFilters () {
        setTempData({
            minPrice: MIN_PRICE,
            maxPrice: MAX_PRICE,
            minArea: MIN_AREA,
            maxArea: MAX_AREA,
            minFloor: MIN_FLOOR,
            maxFloor: MAX_FLOOR,
        })
        clearFilters()
    }


    //Options
    const projectsOptions = projects.map(item => {
        return {
            value: item.id,
            label: item.title
        }
    })

    const typesOptions = Object.keys(AREA_TYPES).map(type => {
        return {
            value: AREA_TYPES[type]?.key,
            label: staticTexts?.[AREA_TYPES?.[type]?.title]
        }
    })

    const menuBadges = !!badges?.length && (
        <Menu className="statuses-dropdown">
            {badges?.filter(badge => badge?.id !== AVAILABLE_BADGE_ID)?.map((badge) => {
                return <Menu.Item key={badge.id}>
                    <div onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        getStatusValue(badge?.id)
                    }
                    }>
                        <Radio className={'house-filter-checkbox'} checked={filters?.statuses?.includes(badge?.id)}>
                            {badge?.title}
                        </Radio>
                    </div>
                </Menu.Item>
            })
            }
        </Menu>
    );

    const typeSelectValue = filters?.types?.map((type) => staticTexts?.[AREA_TYPES?.[type]?.title])?.join(", ")
    let projectsSelectValue = [];
    projects?.forEach((project) => filters?.projects?.includes(project.id) && projectsSelectValue.push(project?.title))

    return <div className={`filters ${showMenu ? 'mobile' : ''}`}>
        <div className="filters__close" onClick={toggleMenu}>
            <ClearIcon/>
        </div>
        <InputGroup
            showSearch={false}
            inputType={'select'}
            value={typeSelectValue || undefined}
            name={'types'}
            className={'filter-item'}
            placeholder={staticTexts?.homepage_slider_apartment_placeholder}
            dropdownRender={() =>
                <Checkbox.Group options={typesOptions}
                                value={filters?.types}
                                onChange={(e) => getSelectValue('types', e)}/>
            }
        />
        <InputGroup
            showSearch={false}
            value={projectsSelectValue?.join(", ") || undefined}
            name={'projects'}
            inputType={'select'}
            placeholder={staticTexts?.filter_projects_title}
            className={'filter-item'}
            dropdownRender={() =>
                <Checkbox.Group options={projectsOptions}
                                value={filters.projects}
                                onChange={(e) => getSelectValue('projects', e)}/>
            }
        />

        <div className={'rooms-count filter-item'}>
            <div className={'rooms-count__title'}>{staticTexts?.filter_rooms_count_title}</div>
            <div className={'rooms-count__list'}>
                {ROOM_COUNTS?.map((number) => {
                    return <button key={`${number}`}
                                   className={`rooms-count__item ${filters?.roomsCount?.includes(number) ? 'active' : ''}`}
                                   onClick={() => {
                                       getSelectValue('roomsCount', filters?.roomsCount?.includes(number)
                                           ? filters?.roomsCount?.filter(item => item !== number)
                                           : [...filters?.roomsCount, number])
                                   }}
                                   disabled={!!filters?.types?.includes(AREA_TYPES.PARKING.key) &&
                                   filters?.types?.length === 1}>
                        <span className="line"/>
                        {number === 4 ? number + '+' : number}
                    </button>
                })}
                <span className="line"/>
            </div>
        </div>

        <div className={'slider-filter filter-item'}>
            <div className={'slider-filter-top'}>
                <div>
                    <span className={'slider-label'}>{staticTexts?.homepage_slider_from}</span>
                    <span className={'dark-text'}>{priceWithCommas(tempData.minPrice)}&nbsp;֏</span>
                </div>
                <div>
                    <span className={'slider-label'}>{staticTexts?.homepage_slider_to}</span>
                    <span className={'dark-text'}>{priceWithCommas(tempData.maxPrice)}&nbsp;֏</span>
                </div>
            </div>
            <Slider range
                    min={MIN_PRICE}
                    max={MAX_PRICE}
                    value={[typeof tempData.minPrice === 'number' ? tempData.minPrice : MIN_PRICE, typeof tempData.maxPrice === 'number' ? tempData.maxPrice : MAX_PRICE]}
                    tipFormatter={null}
                    step={10000}
                    onChange={(value) => onChangeSliderTempData(value, 'Price')}
                    onAfterChange={onAfterChangePrice}
            />
        </div>

        <div className={'slider-filter filter-item'}>
            <div className={'slider-filter-top'}>
                <div>{staticTexts?.homepage_slider_from}
                    <span className={'dark-text'}>{(tempData.minArea)}&nbsp;{staticTexts?.measurement_unit_meter}
                        <sup>2</sup></span>
                </div>
                <div>{staticTexts?.homepage_slider_to}
                    <span className={'dark-text'}>{tempData.maxArea}&nbsp;{staticTexts?.measurement_unit_meter}
                        <sup>2</sup></span>
                </div>
            </div>
            <Slider range
                    min={MIN_AREA}
                    max={MAX_AREA}
                    value={[typeof tempData.minArea === 'number' ? tempData.minArea : MIN_PRICE, typeof tempData.maxArea === 'number' ? tempData.maxArea : MAX_PRICE]}
                    tipFormatter={null}
                // step={10}
                    onChange={(value) => onChangeSliderTempData(value, 'Area')}
                    onAfterChange={onAfterChangeArea}
            />
        </div>

        <div className={'slider-filter filter-item'}>
            <div className={'slider-filter-top'}>
                <div>{staticTexts?.homepage_slider_from}<span className={'dark-text'}>{tempData.minFloor}</span>
                </div>
                <div>{staticTexts?.homepage_slider_to}<span
                    className={'dark-text'}>{tempData.maxFloor}</span></div>
            </div>
            <Slider range
                    min={MIN_FLOOR}
                    max={MAX_FLOOR}
                    value={[typeof tempData.minFloor === 'number' ? tempData.minFloor : MIN_FLOOR, typeof tempData.maxFloor === 'number' ? tempData.maxFloor : MAX_FLOOR]}
                    tipFormatter={null}
                    disabled={!!filters?.types?.includes(AREA_TYPES.PARKING.key)}
                    onChange={(value) => onChangeSliderTempData(value, 'Floor')}
                    onAfterChange={onAfterChangeFloor}
            />
        </div>

        <div className={'filter-item status-filter'}>
            <Dropdown overlay={menuBadges}
                      trigger={['click']}
                      overlayClassName={'statuses-wrapper'}
                      placement="bottomLeft"
                      size={'small'}>
                <div className={"dropdown-btn"}>
                    <div>
                        <Radio className={'house-filter-checkbox'} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation()
                            getStatusValue(AVAILABLE_BADGE_ID)
                        }}
                               checked={filters?.statuses?.includes(AVAILABLE_BADGE_ID)}
                        />{avaiableBadge && `${avaiableBadge?.title}`}&nbsp;
                        {avaiableBadge && <span className={'dark-text'}>{areaData?.availableCount || 0}</span>}
                    </div>
                    <ArrowIcon/>
                </div>
            </Dropdown>
        </div>

        <div className={'clear-filter'} onClick={resetFilters}>
            {staticTexts?.filter_clear_filters}
        </div>

        <PrimaryButton cb={()=>{
            window.innerWidth <= 700 && toggleMenu()
        }} title={staticTexts?.homepage_slider_button_houses.replace('%d', areaData?.count)}/>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    // names (type - string) of props
    'staticTexts',
    'projects',
    'badges',
    'areaData',
    'initialAreaData',
]);

const mapDispatchToProps = {
    //redux actions
    GetAreas
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
