import { AREAS_CONSTS } from "../constants";

export const initialState = {
    areaData: null,
    initialAreaData: null,
    projectsAreas: null,
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case AREAS_CONSTS.GET_AREAS_INFO:
            return {
                ...state,
                areaData: action.payload,
            };
        case AREAS_CONSTS.GET_AREAS_INITIAL_INFO:
            // const areaData = state.areaData ? state.areaData : action.payload;
            return {
                ...state,
                initialAreaData: action.payload,
                areaData: action.payload
            };
        case AREAS_CONSTS.RESET_AREAS_INFO:
            return {
                ...state,
                areaData: state.initialAreaData,
            };
        case AREAS_CONSTS.GET_AREAS:
            return {
                ...state,
                projectsAreas: {
                    ...state.projectsAreas,
                    projects: state.projectsAreas.projects.map(item => {
                        return action.payload.id === item.id ? {
                            ...item,
                            areas: [...item.areas, ...action.payload.data.areas]
                        } : item
                    })
                },
            };
        case AREAS_CONSTS.GET_AREA_SEARCH:
            return {
                ...state,
                projectsAreas: action.payload,
                areaData: {
                    ...state.areaData,
                    count: action.payload.count,
                    availableCount: action.payload.availableCount
                },
            };
        default:
            return state;
    }
}
