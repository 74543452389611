// import packages
import React, {useState} from "react";
import {connect} from "react-redux";
import {Checkbox, Modal, TimePicker} from 'antd';
import moment from "moment";

// Import assets
import "./call-modal.scss"

// Import Components
import {InputGroup} from "../../ui-elements/input-group/inputGroup";

// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {SendCallRequest} from "../../../redux/actions";
import {RESERVE_MODAL_TITLE_TYPES} from "../../../constants/constants";


function CallModal(props) {
    const {house, staticTexts} = props
    const [showTime, setShowTime] = useState(false);
    const [fieldsData, setFieldsData] = useState({
        fullName: "",
        phoneNumber: "",
        time: null,
    })
    const [errors, setErrors] = useState({
        fullName: "",
        phoneNumber: "",
        time: "",
    })
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmBtnDisable, setConfirmBtnDisable] = useState(false);
    const [modalTitleType, setModalTitleType] = useState("");

    const confirmCall = () => {
        if (validate()) {
            setConfirmLoading(true);
            let reqData = {
                ...fieldsData,
                time: moment(fieldsData.time).format('HH:mm')
            }

            !fieldsData.time && (delete reqData.time)
            props.SendCallRequest(reqData).then(() => {
                setTimeout(() => {
                    setConfirmLoading(false);
                    setModalTitleType(RESERVE_MODAL_TITLE_TYPES.success);
                    setConfirmBtnDisable(true)
                }, 2000)
                setTimeout(() => {
                    // setModalTitleType(RESERVE_MODAL_TITLE_TYPES.title);
                    // setConfirmBtnDisable(false)
                }, 7000)
            }).catch((err) => {
                // console.log(err.status, 'err');
            })
        }
    };

    const closeModal = () => {
        props.toggleVisibility()
        setFieldsData({
            fullName: "",
            phoneNumber: "",
            time: null,
        })
        setErrors({
            fullName: "",
            phoneNumber: "",
            time: "",
        })
        setShowTime(false)
        setConfirmBtnDisable(false)
        setModalTitleType(RESERVE_MODAL_TITLE_TYPES.title);
    };

    function getInputValues(e) {
        const {name, value} = e.target;
        if (name === "phoneNumber" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })

        setErrors({
            ...errors,
            [name]: false,
        })
    }

    function validate() {
        const errors = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {

            if ((!fieldsData[key] && showTime && key !== 'time')
                || (key === 'fullName' && fieldsData[key]?.length === 0)) {
                errors[key] = true;
                result = false;
            }

            if (key === 'phoneNumber' && fieldsData[key]?.length <= 8){
                errors[key] = true;
                result = false;
            }

        })
        setErrors(errors);
        return result;
    }

    function getDisabledHours() {
        let hours = [];
        for (let i = 0; i < 24; i++) {
            if (i < 10 || i > 18) {
                hours.push(i);
            }
        }
        return hours;
    }

    const text = modalTitleType === RESERVE_MODAL_TITLE_TYPES.success
        ? staticTexts?.modal_success_message : (modalTitleType === RESERVE_MODAL_TITLE_TYPES.error
            ? staticTexts?.modal_error_message : staticTexts?.call_modal_title || ' ')

    return <Modal
        title={text}
        className={`call-modal ${modalTitleType === RESERVE_MODAL_TITLE_TYPES.success ? "success" :
            (modalTitleType === RESERVE_MODAL_TITLE_TYPES.error ? "error" : "")} ${confirmBtnDisable ? "confirm-btn-disable" : ""}`}
        centered
        cancelText={confirmBtnDisable ? staticTexts?.modal_btn_close : staticTexts?.modal_btn_cancel}
        okText={staticTexts?.modal_btn_save}
        visible={props.visible}
        onOk={!confirmBtnDisable && confirmCall}
        confirmLoading={confirmLoading}
        onCancel={closeModal}>

        <InputGroup
            inputType={'input'}
            type={"text"}
            value={fieldsData.fullName}
            error={errors.fullName}
            name={'fullName'}
            maxLength={100}
            label={staticTexts?.modal_name_label}
            onChange={getInputValues}
        />
        <div className="row">
            <InputGroup
                inputType={'input'}
                type={"text"}
                value={fieldsData.phoneNumber}
                error={errors.phoneNumber}
                name={'phoneNumber'}
                maxLength={12}
                label={staticTexts?.modal_phone_label}
                onChange={getInputValues}
            />

            {
                showTime ? <InputGroup inputType={'wrapper'} label={staticTexts?.call_time_picker_placeholder} value={fieldsData.time}>
                        <TimePicker value={fieldsData.time}
                                    placeholder={''}
                                    allowClear={false}
                                    disabledHours={getDisabledHours}
                                    minuteStep={15}
                                    format={'HH:mm'}
                                    className="input-time"
                                    popupClassName="popup-input-time"
                                    onSelect={(time) => getInputValues({target: {name: 'time', value: time}})}/>
                    </InputGroup>
                    : <div className="time-checkbox-wrapper">
                        <Checkbox onChange={() => setShowTime(!showTime)}
                                  checked={showTime}>
                            {staticTexts?.call_time_placeholder}
                        </Checkbox>
                    </div>
            }
        </div>

    </Modal>

}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
]);

const mapDispatchToProps = {
    SendCallRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(CallModal);