// Import packages
import React, {useEffect, useState} from "react";
import {useLocation, withRouter} from 'react-router-dom';
import {connect} from "react-redux";

// Import assets
import '../assets/styles/layout.scss'

// Import components
import Header from "../components/Header";
import Footer from "../components/Footer";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {
    GetLanguages,
    GetStaticTexts,
    GetMenu,
    GetAbout,
    GetMainSlider,
    GetAboutSlider,
    GetContact,
    GetBadges,
    GetBanks,
    GetProjects,
    GetAreasInitialInfo, GetPartners,
} from "../redux/actions";
import MetaTags from "../components/MetaTags";
import {CallIcon} from "../assets/images";
import CallModal from "../components/modal/callModal/CallModal";

function Layout(props) {
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
    const [visible, setVisible] = useState(false);

    const location = useLocation();

    useEffect(() => {
        props.GetLanguages()
        props.GetStaticTexts()
        props.GetMenu()
        props.GetMainSlider().finally(() => {
            props.GetAreasInitialInfo()
            props.GetAbout()
            props.GetAboutSlider()
            props.GetContact()
            props.GetBadges()
            props.GetBanks()
            props.GetProjects()
            props.GetPartners()
        })
    }, [props.selectedLanguage?.id]);


    const toggleMobileMenu = () => {
        setMobileMenuIsOpen(!mobileMenuIsOpen)
    };

    const toggleVisibility = () => {
        setVisible(!visible)
    }

    const {children} = props;
    const showFooter = !location.pathname.includes('filter')
    return <div className={`LayoutWrapper ${mobileMenuIsOpen ? "mobileMenuOpen" : ""}`}>
        <MetaTags/>
        <button className="call-btn" onClick={toggleVisibility}>
            <CallIcon/>
        </button>
        <Header mobileMenuIsOpen={mobileMenuIsOpen}
                toggleMobileMenu={toggleMobileMenu}/>
        <div className={`MainContent`}>
            {children}
        </div>
        {showFooter && <Footer/>}
        <CallModal visible={visible}
                   toggleVisibility={toggleVisibility}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'languages',
        'selectedLanguage'
    ])
};

const mapDispatchToProps = {
    GetLanguages,
    GetStaticTexts,
    GetMenu,
    GetAbout,
    GetMainSlider,
    GetAboutSlider,
    GetContact,
    GetBadges,
    GetBanks,
    GetProjects,
    GetAreasInitialInfo,
    GetPartners,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
