// import packages
import React, {useEffect, useRef, useState} from "react";

// Import assets
import "./homepage.scss"

// Import Components
import Contact from "./contact/Contact";
import MainSlider from "./slider/MainSlider";
import About from "./about/About";
import Futures from "./futures/Futures";
import {ClearIcon, FilterIcon} from "../../assets/images";
import Partners from "./partners/Partners";

function Homepage() {
    //Ref
    const mainRef = useRef(null);
    const buttonRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [show, setShow] = useState(false)


    useEffect(() => {
        window?.addEventListener("scroll", () => {
            setShow(window?.scrollY > mainRef?.current?.clientHeight)
        });
    }, [])

    return <div className={'Homepage'}>
        {show && <>
            <button className="search-panel-button"
                    ref={buttonRef}
                    onClick={() => setOpen(!open)}>
                {
                    !open ? <FilterIcon/> : <ClearIcon/>
                }
            </button>
        </>}
        <MainSlider
            refProp={mainRef}
            searchBoxIsSticky={show}
            stickyRowIsOpen={open}
            toggleStickyRow={setOpen}
        />
        <About/>
        <Futures/>
        <Partners/>
        <Contact/>
    </div>
}

export default Homepage;
