// import packages
import React, { useState, useRef, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import queryString from 'query-string'

// Import assets
import "./filtersPage.scss"

// Import Components
import { InputGroup } from "../../components/ui-elements/input-group/inputGroup";
import { PrimaryButton } from "../../components/ui-elements/buttons/Buttons";
import Map from "./map/FilterMap";
import MapWindow from "./mapWindow/MapWindow";
import { Breadcrumb } from "antd";
import { FilterIcon, Icon360, PlusIcon } from "../../assets/images";
import ApartmentsCard from "../../components/cards/apartmentCard/ApartmentsCard";
import Filters from "./filters/Filters";

// Import utils
import {
    AREAS_SORT_TYPES, AVAILABLE_BADGE_ID,
    BUILDINGS_360_LINKS, BUILDINGS_KEYS,
    PROJECTS_CONSTS
} from "../../constants/constants";
import { AREA_TYPES } from "../../constants/constType";
import { getPropsFromState } from "../../redux/mapStateToProps";
import { GetAreaSearch, GetAreas, } from "../../redux/actions";
import { getUrlWithLocal } from "../../utils/getUrlWithLocal";
import { generateFileMediaUrl } from "../../utils/generateMediaUrl";
import { history } from "../../configs/history";
import { toggleMapWindow } from "../../utils/toggleMapWindow";

function FiltersPage (props) {
    const {
        projects,
        initialAreaData,
        staticTexts,
        projectsAreas,
        selectedLanguage,
        GetAreas,
    } = props

    //constants
    const MIN_PRICE = initialAreaData?.minPrice;
    const MAX_PRICE = Math.ceil(initialAreaData?.maxPrice / 100000) * 100000;
    const MIN_AREA = initialAreaData?.minArea;
    const MAX_AREA = initialAreaData?.maxArea;

    const filtersInitialValues = {
        types: [AREA_TYPES.APARTMENT?.key],
        floors: [],
        roomsCount: [],
        minPrice: undefined,
        maxPrice: undefined,
        minArea: undefined,
        maxArea: undefined,
        sort: AREAS_SORT_TYPES.PRICE_UP,
        statuses: [AVAILABLE_BADGE_ID],
        buildings: [],
        projects: [],
    }
    const InitialValues = {
        types: [AREA_TYPES.APARTMENT?.key],
        floors: [],
        roomsCount: [],
        minPrice: MIN_PRICE,
        maxPrice: MAX_PRICE,
        minArea: MIN_AREA,
        maxArea: MAX_AREA,
        sort: AREAS_SORT_TYPES.PRICE_UP,
        statuses: [],
        buildings: [],
        projects: [],
    }

    const sortOptions = [
        { name: `${staticTexts?.sort_by_price_increase}`, id: AREAS_SORT_TYPES.PRICE_UP },
        { name: `${staticTexts?.sort_by_price_reduction}`, id: AREAS_SORT_TYPES.PRICE_DOWN },
        { name: `${staticTexts?.sort_by_area_increase}`, id: AREAS_SORT_TYPES.AREA_UP },
        { name: `${staticTexts?.sort_by_area_reduction}`, id: AREAS_SORT_TYPES.AREA_DOWN },
        { name: `${staticTexts?.sort_by_rooms_count_increase}`, id: AREAS_SORT_TYPES.ROOM_COUNTS_UP },
        { name: `${staticTexts?.sort_by_rooms_count_reduction}`, id: AREAS_SORT_TYPES.ROOM_COUNTS_DOWN },
    ]
    const location = useLocation()
    const queryParams = queryString.parse(location.search)
    const [filters, setFilters] = useState(filtersInitialValues)
    const [showMenu, setShowMenu] = useState(false)
    const [focusedProject, setFocusedProject] = useState(projects[0])
    const [mapRendered, setMapRendered] = useState(false)
    const [mounted, setMounted] = useState(false)


    //hooks
    const ref = useRef()
    const section_1 = useRef()
    const section_2 = useRef()
    const section_3 = useRef()

    const section_ref = [section_1, section_2, section_3]
    //hooks
    useMemo(() => {
        window.scrollTo({
            top: 0
        });
        // console.log('location?.state', location?.state);
        const { minPrice, maxPrice, roomsCount, types, projects, statuses } = location?.state || {}
        const _filters = {}
        // console.log('type', type);
        minPrice && (_filters.minPrice = minPrice);
        maxPrice && (_filters.maxPrice = maxPrice);
        roomsCount && (_filters.roomsCount = roomsCount);
        types && (_filters.types = types);
        projects && (_filters.projects = projects);
        statuses && (_filters.statuses = statuses);
        // console.log(_filters, '-----filters');
        const newFilters = {
            ...filters,
            ..._filters,
        }
        setFilters(newFilters)
        setMounted(true)
        requestNewSearchData({ filters: newFilters })
        history.replace(location.pathname + location.search, {}); // clear location state (before refreshing bug)
    }, [])

    async function requestNewSearchData (payload) {
        const requestFilters = payload?.filters || filters;
        const requestConfig = payload?.config || {}
        const reqData = {
            ...requestFilters,
            offset: 0,
            ...requestConfig,
        }
        await props.GetAreaSearch(reqData)
    }

    useEffect(() => {
        setFocusedProject(projects[0])
    }, [projects])

    useEffect(() => {
        projectsAreas?.projects && projectsAreas?.projects?.forEach(project => setFocusedProject(
            projects.find(item => item.id === project?.id)
        ))
    }, [filters.projects])


    function changeFilter (changedFilters = {}) { // object of changed filters => {key1: value, key2: value}
        let newFilters = {
            ...filters,
            ...changedFilters
        };
        setFilters(newFilters);
        requestNewSearchData({ filters: newFilters })
    }

    function clearFilters () {
        setFilters(filtersInitialValues);
        requestNewSearchData({ filters: filtersInitialValues })
    }

    function getMoreAreas (id, offset) {
        GetAreas({
            ...filters,
            statuses: filters.statuses,
            offset,
            reset: false,
            projects: id,
        })
    }

    function getSelectValue (name, value) {
        changeFilter({ [name]: value })
    }

    function toggleMenu () {
        setShowMenu(!showMenu)
    }

    function scrollProducts () {
        section_ref.forEach((item, index) => {
            if (ref?.current?.getBoundingClientRect()?.top > (item?.current?.getBoundingClientRect()?.top - 200)) {
                setFocusedProject(projects[index])
            }
        })
    }

    function sortType () {
        if (filters?.sort?.includes(AREAS_SORT_TYPES.ROOM_COUNTS_UP)) {
            return staticTexts?.filter_sort_type_rooms_count
        } else if (filters?.sort?.includes(AREAS_SORT_TYPES.AREA_UP)) {
            return staticTexts?.filter_sort_type_area
        } else return staticTexts?.filter_sort_type_price
    }

    function selectBuilding (id) {
        const buildings = filters.buildings?.includes(id) ? filters.buildings.filter(i => i !== id) : [id]
        changeFilter({ 'buildings': buildings })
    }

    return <div className={'filters-page'}>
        {mounted && <>{
            window.innerWidth > 700 ?
                <Filters toggleMenu={toggleMenu}
                         showMenu={showMenu}
                         changeFilter={changeFilter}
                         clearFilters={clearFilters}
                         filters={filters}/> :
                showMenu &&
                <Filters toggleMenu={toggleMenu}
                         showMenu={showMenu}
                         changeFilter={changeFilter}
                         clearFilters={clearFilters}
                         filters={filters}/>
        }</>}
        <div className="filters-page__projects">
            <Breadcrumb className="filters-page__projects__header">
                <Breadcrumb.Item><Link
                    to={getUrlWithLocal('/home')}>{staticTexts?.rout_item_home}</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{staticTexts?.rout_item_buildings}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="filters-page__projects__content">
                <div className="filters-page__projects__content__list" ref={ref} onScroll={scrollProducts}>
                    <div className="filters-page__projects__content__filter">
                        <div className={'sort'}>
                            <span className={'sort__label'}>{sortType()}:&nbsp;</span>
                            <InputGroup
                                showSearch={false}
                                inputType={'select'}
                                value={filters.sort}
                                name={'sort'}
                                placeholder={`${staticTexts?.filter_sort_placeholder}`}
                                onChange={getSelectValue}
                                options={sortOptions}/>
                        </div>
                        <div
                            className={'sort_count'}>{staticTexts?.filter_showing_count?.replace('%d', projectsAreas?.count)
                            ?.replace('%l', projectsAreas?.projects?.length)}</div>
                    </div>
                    <PrimaryButton className={'filters-page__projects__content__filter_btn'}
                                   cb={toggleMenu}
                                   title={<><FilterIcon/> {staticTexts?.filter_open_filter_menu}</>}/>
                    {projectsAreas?.projects?.map((item, index) => {
                        return <div className="filter_projects" ref={section_ref[index]} key={item?.id}>
                            <div className="filter_projects__project">
                                <div className="info">
                                    {/*<div className="info__image">*/}
                                    <Link to={getUrlWithLocal(`/buildings/${item?.slug}`)} className={'info__image'}>
                                        <img src={generateFileMediaUrl(item?.mediaMain?.path)}
                                             className={''} alt={""}/>
                                    </Link>

                                    {/*</div>*/}
                                    <div className="info__content">
                                        <div className="project_title">{item?.title}</div>
                                        <div className="project_address">{item?.address}</div>
                                    </div>
                                    {BUILDINGS_360_LINKS[item?.slug] ? <Link
                                        to={getUrlWithLocal(`stories-360/${item?.slug}/${PROJECTS_CONSTS[item?.slug]?.floor_count}`)}
                                        className="info__view">
                                        {/*{staticTexts?.filter_360_btn}*/}
                                        <Icon360/>
                                    </Link> : <span className="info__view"/>}
                                </div>
                                <div className="project-actions">
                                    <PrimaryButton title={staticTexts?.filter_3d_btn}
                                                   link={getUrlWithLocal(`/buildings/${item?.slug}`)}/>
                                    <div className={'buildings-btn'}>
                                        {
                                            item?.buildings?.map(item => {
                                                return BUILDINGS_KEYS.A2 !== item.slug && <PrimaryButton
                                                    className={`${filters?.buildings.includes(item.id) ? '' : 'bg-grey'}`}
                                                    title={item.slug}
                                                    cb={() => selectBuilding(item?.id)}
                                                    key={item.id}/>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            {item?.areas?.map(area => {
                                return <ApartmentsCard house={area} key={area?.id}/>
                            })}
                            {(item?.areasCount > 3 && item?.areas?.length < item?.areasCount) ?
                                <div className="filters-page__projects__content__more">
                                    <div className={'more-btn'}
                                         onClick={() => getMoreAreas(item?.id, item?.areas?.length)}>
                                        <div className="svg"><PlusIcon/></div>
                                        {staticTexts?.filter_show_more_btn}
                                    </div>
                                    {/*<div>{staticTexts?.filter_project_areas_count?.replace('%d', item?.areas?.length)*/}
                                    {/*    ?.replace('%l', item?.areasCount)}</div>*/}
                                </div> : ""
                            }
                        </div>
                    })
                    }
                </div>
                <Map focusedProject={focusedProject}
                     setMapRendered={setMapRendered}
                     selectedLanguage={selectedLanguage}
                     projects={projectsAreas?.projects}/>
            </div>
        </div>
        {(mapRendered || queryParams.map) && <div className={`${queryParams.map ? 'showFilterMap' : 'closeFilterMap'}`}>
            <MapWindow projects={projects}/>
        </div>}
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    // names (type - string) of props
    'staticTexts',
    'projects',
    'projectsAreas',
    'areaData',
    'selectedLanguage',
    'initialAreaData',
]);

const mapDispatchToProps = {
    //redux actions
    GetAreaSearch,
    GetAreas
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPage);
