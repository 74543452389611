import {_hostMedia} from "../redux/api";
import defaultFileLogo from '../assets/images/default.png';

export function generateImageMediaUrl(path, type) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path ? `${_hostMedia}/images${path}` : defaultFileLogo
}

export function generateFileMediaUrl(path) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path ? `${_hostMedia}/files${path}` : defaultFileLogo;
}
export function generateSliderMediaUrl(path) {
    if (path?.startsWith('http')) {
        return path;
    }
    return `${_hostMedia}/files${path}`;
}

export function generateFileMediaUrlWithoutDefault(path) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path && `${_hostMedia}/files${path}`
}
