// import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//Import assets
import './custom-pages.scss';

//Import Utils
import { getPropsFromState } from "../../redux/mapStateToProps";
import { GetCustomPages } from "../../redux/actions";
import { generateFileMediaUrl } from "../../utils/generateMediaUrl";
import { ArrowIcon } from "../../assets/images";

class CustomPage extends Component {
    constructor (props) {
        super(props);
        this.state = {
            extensionsOpen: false
        };
    }

    componentDidMount () {
        const slug = this.props?.match?.params?.slug;
        this.props.GetCustomPages(slug);
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevProps?.match?.params?.slug !== this.props?.match?.params?.slug ||
            prevProps.selectedLanguage !== this.props.selectedLanguage) {
            const slug = this.props?.match?.params?.slug;
            this.props.GetCustomPages(slug);
        }
    }

    render () {
        const { extensionsOpen } = this.state;
        const { customPages } = this.props;
        let contentExtension;
        if (customPages?.contentExtension) {
            let text = customPages?.contentExtension?.trim()
            let words = text.split(' ');
            const lastWord = words.pop()
            contentExtension =
                <div className="custom-page-content-extension">{words.join(' ')} <span
                    onClick={() => {
                        this.setState({ extensionsOpen: !extensionsOpen })
                    }}
                    className={extensionsOpen ? 'rotated' : ''}
                >{lastWord}<ArrowIcon/></span></div>
        }
        return <div className="custom-page-wrapper">
            <div className="header-content">
                {customPages?.mediaMain?.path && <img src={generateFileMediaUrl(customPages?.mediaMain?.path)}
                                                      alt=""
                                                      className="custom-page-img main-img"/>}
                {customPages?.title && <div className="header-title">{customPages?.title}</div>}
            </div>
            <div className="custom-page-content">
                {customPages?.content && <div dangerouslySetInnerHTML={{ __html: customPages?.content }}/>}
                {contentExtension}
                {extensionsOpen && customPages?.mediaSections && !!customPages?.mediaSections?.length &&
                <div className={'extensions-wrapper'}>
                    {customPages.mediaSections.map(item => {
                        return <div className={'extension-item'}>
                            <img src={generateFileMediaUrl(item?.mediaMain?.path)}/>
                            <span> {item?.translations?.title}</span>
                        </div>
                    })}
                </div>}

            </div>

        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'customPages',
        'selectedLanguage',
    ])
};

const mapDispatchToProps = { GetCustomPages };

export default connect(mapStateToProps, mapDispatchToProps)(CustomPage);
