// import packages
import React, { useEffect, useState } from "react";
import { Checkbox, Slider } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Import assets
import "./home-filter.scss";
import { CalculatorIcon, ClearIcon } from "../../../../assets/images";

//Import Components

// Import utils
import { getPropsFromState } from "../../../../redux/mapStateToProps";
import { getUrlWithLocal } from "../../../../utils/getUrlWithLocal";
import { GetAreasInfo, ResetAreasInfo } from "../../../../redux/actions";
import { InputGroup } from "../../../../components/ui-elements/input-group/inputGroup";
import { AREA_TYPES } from "../../../../constants/constType";
import { AVAILABLE_BADGE_ID, ROOM_COUNTS } from "../../../../constants/constants";
import { priceWithCommas } from "../../../../utils/helperFunctions";
import { PrimaryButton } from "../../../../components/ui-elements/buttons/Buttons";


const roomCountsOptions = ROOM_COUNTS.map(number => {
    return {
        value: number,
        label: number === 4 ? number + '+' : number
    }
})

function HomeFilter (props) {
    const { staticTexts, areaData, initialAreaData, projects } = props
    let MIN_PRICE = initialAreaData?.minPrice || 0;
    let MAX_PRICE = Math.ceil(initialAreaData?.maxPrice / 100000) * 100000 || 0;
    const filtersInitialValues = {
        types: [AREA_TYPES.APARTMENT?.key],
        projects: [],
        roomsCount: undefined,
        minPrice: MIN_PRICE,
        maxPrice: MAX_PRICE,
        statuses: [AVAILABLE_BADGE_ID]
    }
    //Options
    const projectsOptions = projects?.map(item => {
        return {
            value: item.id,
            label: item.title
        }
    })

    const typesOptions = Object.keys(AREA_TYPES).map(type => {
        return {
            value: AREA_TYPES[type]?.key,
            label: staticTexts?.[AREA_TYPES?.[type]?.title]
        }
    })

    //State
    const [filters, setFilters] = useState(filtersInitialValues)
    const [tempData, setTempData] = useState({
        minPrice: MIN_PRICE,
        maxPrice: MAX_PRICE,
    })
    //Hooks
    useEffect(() => {
        !!initialAreaData && ResetAreasInfo()
    }, [])

    useEffect(() => {
        setFilters(filtersInitialValues);
        setTempData({
            minPrice: MIN_PRICE,
            maxPrice: MAX_PRICE,
        })
    }, [initialAreaData?.minPrice])

    //Functions
    const onChangeSlider = (values) => {
        setTempData({
            ...tempData,
            minPrice: values[0],
            maxPrice: values[1],
        })
    }

    function onAfterChangePrice (values) {
        const state = {
            ...filters,
            minPrice: values[0] !== MIN_PRICE ? values[0] : undefined,
            maxPrice: values[1] !== MAX_PRICE ? values[1] : undefined
        }
        changeFilterState(state)
    }

    function changeFilter (name, value) {
        let state = {
            ...filters,
            [name]: value
        };
        changeFilterState(state);
    }

    function changeFilterState (state) {
        setFilters(state);
        props.GetAreasInfo(state)
    }

    function removeFilters () {
        setFilters(filtersInitialValues);
        setTempData({
            minPrice: MIN_PRICE,
            maxPrice: MAX_PRICE,
        })
        ResetAreasInfo()
    }

    function getSelectValue (e, name) {
        changeFilter(name, e)
    }


    function onClose () {
        props.toggleStickyRow(false)
    }


    const typeSelectValue = filters?.types?.map((type) => staticTexts?.[AREA_TYPES?.[type]?.title])?.join(", ")
    const roomsCountSelectValue = filters?.roomsCount?.join(", ")
    let projectsSelectValue = [];
    projects?.forEach((project) => filters?.projects?.includes(project.id) && projectsSelectValue.push(project?.title))


    return <>
        <div className={'home-filter-wrapper'}>
            <div className={'clear-filters-wrapper'}>
                {areaData !== initialAreaData &&
                <span onClick={removeFilters}>{staticTexts?.filter_clear_filters}</span>}
            </div>
            <InputGroup
                showSearch={false}
                inputType={'select'}
                value={typeSelectValue || undefined}
                name={'types'}
                dropdownMatchSelectWidth={false}
                placeholder={staticTexts?.homepage_slider_apartment_placeholder}
                dropdownRender={() =>
                    <Checkbox.Group options={typesOptions}
                                    value={filters?.types}
                                    onChange={(e) => getSelectValue(e, 'types')}/>
                }
            />
            <InputGroup
                showSearch={false}
                inputType={'select'}
                value={roomsCountSelectValue || undefined}
                name={'roomsCount'}
                dropdownMatchSelectWidth={false}
                placeholder={staticTexts?.homepage_slider_rooms_count_placeholder}
                disabled={!filters?.types?.includes(AREA_TYPES.APARTMENT?.key)}
                dropdownRender={() =>
                    <Checkbox.Group options={roomCountsOptions}
                                    value={filters?.roomsCount}
                                    onChange={(e) => getSelectValue(e, 'roomsCount')}/>
                }
            />
            <InputGroup
                showSearch={false}
                inputType={'select'}
                value={projectsSelectValue?.join(", ") || undefined}
                name={'projects'}
                dropdownMatchSelectWidth={false}
                placeholder={staticTexts?.homepage_slider_project_placeholder}
                dropdownRender={() =>
                    <Checkbox.Group options={projectsOptions}
                                    value={filters.projects}
                                    onChange={(e) => getSelectValue(e, 'projects')}/>
                }
            />

            <div className={'slider-filter'}>
                <div className={'slider-filter-top'}>
                    <div>{staticTexts?.homepage_slider_from} {priceWithCommas(tempData.minPrice)}</div>
                    <div>{staticTexts?.homepage_slider_to} {priceWithCommas(tempData.maxPrice)}</div>
                </div>
                <Slider range
                        min={MIN_PRICE}
                        max={MAX_PRICE}
                        value={[
                            typeof tempData.minPrice === 'number' ? tempData.minPrice : MIN_PRICE,
                            typeof tempData.maxPrice === 'number' ? tempData.maxPrice : MAX_PRICE
                        ]}
                        tipFormatter={null}
                        step={10000}
                        onChange={(value) => onChangeSlider(value, 'Price')}
                        onAfterChange={onAfterChangePrice}
                />
            </div>

            <Link to={getUrlWithLocal("/calculator")} className="calc_wrapper">
                <CalculatorIcon/>
            </Link>

            <Link to={{
                state: filters,
                pathname: getUrlWithLocal("/filter")
            }} className="btn_wrapper">
                {staticTexts?.homepage_slider_button_houses.replace('%d', areaData?.count || 0)}
            </Link>
        </div>

        {props.searchBoxIsSticky && <div className={`search-panel ${props.stickyRowIsOpen ? 'open_panel' : ''}`}>
            <div className={`search-panel__overlay ${props.stickyRowIsOpen ? 'visible' : ''}`}/>

            <div className={`search-panel__wrapper`}>
                <ClearIcon className={`close-panel`} onClick={onClose}/>
                <InputGroup
                    showSearch={false}
                    inputType={'select'}
                    value={typeSelectValue || undefined}
                    name={'types'}
                    dropdownMatchSelectWidth={false}
                    placeholder={staticTexts?.homepage_slider_apartment_placeholder}
                    dropdownRender={() =>
                        <Checkbox.Group options={typesOptions}
                                        value={filters?.types}
                                        onChange={(e) => getSelectValue(e, 'types')}/>
                    }
                />
                <InputGroup
                    showSearch={false}
                    inputType={'select'}
                    value={projectsSelectValue?.join(", ") || undefined}
                    name={'projects'}
                    dropdownMatchSelectWidth={false}
                    placeholder={staticTexts?.homepage_slider_project_placeholder}
                    dropdownRender={() =>
                        <Checkbox.Group options={projectsOptions}
                                        value={filters.projects}
                                        onChange={(e) => getSelectValue(e, 'projects')}/>
                    }
                />

                <div className={'slider-filter dark'}>
                    <div className={'slider-filter-top'}>
                        <div>{staticTexts?.homepage_slider_from} {priceWithCommas(filters.minPrice)}</div>
                        <div>{staticTexts?.homepage_slider_to} {priceWithCommas(filters.maxPrice)}</div>
                    </div>
                    <Slider range
                            min={MIN_PRICE}
                            max={MAX_PRICE}
                            value={[
                                typeof tempData.minPrice === 'number' ? tempData.minPrice : MIN_PRICE,
                                typeof tempData.maxPrice === 'number' ? tempData.maxPrice : MAX_PRICE
                            ]}
                            tipFormatter={null}
                            step={10000}
                            onChange={(value) => onChangeSlider(value, 'Price')}
                            onAfterChange={onAfterChangePrice}
                    />
                </div>
                <PrimaryButton link={getUrlWithLocal("/filter")}
                               title={staticTexts?.homepage_slider_button_houses.replace('%d', areaData?.count || 0)}/>
            </div>
        </div>}
    </>
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'areaData',
        'projects',
        'initialAreaData',
    ])
};

const mapDispatchToProps = { GetAreasInfo, ResetAreasInfo };

export default connect(mapStateToProps, mapDispatchToProps)(HomeFilter);
